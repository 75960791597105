/* global localStorage */
import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Trans, Plural } from '@lingui/macro';
import { Text } from '../components/Content';
import { NavBar } from '../components/NavBar';
import { ContentInset, Surface } from '../components/Layouts';
import { NullState } from '../components/Content';
import { profileFragment, personFragment } from '../utils/fragments';
import { PersonRow } from '../components/Rows';
import { ReactComponent as GroupTwoTone } from '../icons/GroupTwoTone.svg';
import { Loading, HelpButton, SettingsButton } from '../components/Controls';
import { SectionHeader } from '../components/Text';
import { ReactComponent as ChevronRight } from '../icons/ChevronRight.svg';

export const PeopleQuery = gql`
  ${profileFragment.profile}
  ${personFragment.person}
  query {
    me {
      id
      currentProfile {
        ...ProfileFields
        students {
          ...PersonFields
          listenCount
        }
        invitations {
          person {
            ...PersonFields
          }
        }
      }
    }
  }
`;


const People = props => {
  const { history } = props;
  const { loading, data, error } = useQuery(PeopleQuery);
  return (
        <Surface>
          <NavBar left={<HelpButton />} right={<SettingsButton />}>
            <Text large bold><Trans>Students</Trans></Text>
          </NavBar>
          <ContentInset padding="60px 0px 80px 0px">
            {loading && <Loading />}
            {!loading &&
              !error && (
                <>
                  {!error &&
                    <>
                    <SectionHeader marginLeft="24px"> 
                      {data.me.currentProfile.students.length} {` `}
                      <Plural
                        value={data.me.currentProfile.students.length}
                        one="Student"
                        other="Students"
                      />
                    </SectionHeader>
                    {
                    data.me.currentProfile.students.map(person => (
                      <PersonRow
                        onClick={() => {
                          history.push(`/people/${person.id}`);
                        }}
                        subtext={
                          <> 
                            {person.listenCount} {` `}
                            <Plural
                              value={person.listenCount}
                              one="Listen"
                              other="Listens"
                            /> 
                          </>
                        }
                        button={<ChevronRight />}
                        {...person}
                      /> 
                      ))}
                    </> 
                  }
                  {!error &&
                    data.me.currentProfile.invitations.map(
                      ({ person, ...invitation }) => (
                        <PersonRow
                          onClick={() => {
                            history.push(`/people/${person.id}`);
                        }}
                        {...person}
                        />
                      )
                  )}
                  {!error &&
                    data.me.currentProfile.students.length === 0 &&
                    data.me.currentProfile.invitations.length === 0 && (
                      <NullState icon={<GroupTwoTone />}>
                        <Trans>Invite your first student</Trans>
                      </NullState>
                  )}
                </>
              )}
          </ContentInset>
        </Surface>
  );
};

export default People;
