import React, { useEffect } from 'react';
import { Trans } from '@lingui/macro';
import { NavBar } from '../components/NavBar';
import { Surface, ContentInset } from '../components/Layouts';
import { NullState } from '../components/Content';
import { Button } from '../components/Controls';
import { ReactComponent as Close } from '../icons/Close.svg';
import { ReactComponent as ErrorTwoTone } from '../icons/ErrorTwoTone.svg';

export default function ErrorPage({ history }) {
  useEffect(() => { window.analytics.page('ErrorPage') }, []);
  return (
    <Surface>
      <NavBar
        fixed
        transparent
        left={<Button close nav to="/" history={history} />}
      />
      <ContentInset>
        <NullState icon={<ErrorTwoTone />}>
          <Trans>Something went wrong</Trans>
        </NullState>
        <center>
          <Button as="a" href="/" style={{ width: '50%' }}><Trans>Try Again</Trans></Button>
        </center>
      </ContentInset>
    </Surface>
  );
}

export function EmptyPage() {
  return (
    <Surface>
      <NavBar
        fixed
        transparent
        left={
          <a href="/">
            <Close />
          </a>
        }
      />
      <ContentInset>
      </ContentInset>
    </Surface>
  );
}
