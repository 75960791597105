import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import DocumentTitle from 'react-document-title';
import { ProfileSummary } from '../components/Content';
import { Surface, ContentInset } from '../components/Layouts';

export const ProfileQuery = gql`
  query($username: String!) {
    profile(username: $username) {
      id
      name
      imageUrl
      bio
      websiteUrl
    }
  }
`;

// TODO:
// - if you're already a student, redirect to /posts
//
const Profile = ({ auth, match, history }) => {
  useEffect(() => { window.analytics.page('Profile') }, []);
  const { username } = match.params;
  const { loading, data, error  } = useQuery(ProfileQuery, { variables: { username }});
  return (
    <DocumentTitle title={loading || error ? 'Loading yoga' : data.profile.name}>
      <Surface poweredBy={true}>
        <ContentInset>
          <ProfileSummary {...data.profile || {}} />
          <ContentInset padding="40px 20px 0 20px">
          </ContentInset>
        </ContentInset>
      </Surface>
    </DocumentTitle>
  );
};

export default Profile;
