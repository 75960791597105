export const defaultTheme = {
  name: "Default",
  primary: '#333',
  secondary: '#444',
  tertiary: '#888',
  primaryButtonBg: '#43CDA3',
  primaryButtonBgDisabled: 'rgba(67, 205, 163, 0.5)',
  surfaceBackground: 'white',
  surfaceLine: '#D7D4D3',
  selectedBorderColor: '#27AE60',
  notSelectableColor: '#ae3427',
  navBarBackground: 'white',
  error: '#D00000',
  postCloseLink: 'white',
  iconColours: '#FFFFFF',
};

export const darkTheme = {
  ...defaultTheme,
  primary: '#ddd',
  secondary: '#444',
  surfaceBackground: 'black',
  navBarBackground: 'black'
};
