import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Trans } from '@lingui/macro';
import { Surface, CardGrid } from '../components/Layouts';
import { NavBar } from '../components/NavBar';
import { Text } from '../components/Content';
import { Button, Loading } from '../components/Controls';
import { ImageFieldset } from '../components/Forms';
import { coverFragment } from '../utils/fragments';

const CoverPickQuery = gql`
  query {
    covers(username:"sco") {
      id
      svg
    }
    me {
      id
      currentProfile {
        id
      }
    }
  }
`;

const CreateCover = gql`
  ${coverFragment.cover}
  mutation($profileId: ID!, $url: String!) {
    createCover(profileId: $profileId, url: $url) {
      ...CoverFields
    }
  }
`;

const Wrapper = styled.div`
  width: 180px;
`;

const ImageCard = styled.div`
  background-color: #ccc;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  min-height: 230px;
  overflow: hidden;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const AddImage = () => (
  <Wrapper>
    <ImageCard />
  </Wrapper>
);

const Content = styled.section`
  padding: ${props => props.padding || '50px 18px 30px 18px'};
  max-width: 500px;
  margin: ${props => props.margin};
  height: 100%;
  grid-area: content;
`;

export default function Covers({ onPick, onCancel }) {
  useEffect(() => { window.analytics.page('Covers'); }, []);
  const { data, loading } = useQuery(CoverPickQuery)
  const [mutate] = useMutation(CreateCover);
  return (
    <Formik
      onSubmit={(values, { setSubmitting }) => {
        const variables = {
          url: values.imageUrl,
          profileId: data.me.currentProfile.id
        };
        mutate({ variables })
          .then(result => {
            setSubmitting(false);
            onPick({ coverId: result.data.createCover.id });
          })
          .catch(res => {
            setSubmitting(false);
          });
      }}
      render={({ handleSubmit }) => (
        <Surface>
          <NavBar
            left={<Button close nav onClick={onCancel} />}
            fixed
          >
            <Text bold>
              <Trans>Set Cover</Trans>
            </Text>
          </NavBar>
          <Content padding="4em 1em 5em 1em" margin="1em 0">
            {loading ? (
              <Loading inset />
            ) : (
              <CardGrid>
                {false && <ImageFieldset
                  name="imageUrl"
                  id="imageUrl"
                  width="180"
                  height="200"
                  borderRadius="15px"
                  onComplete={handleSubmit}
                  component={AddImage}
                />}
                {data && data.covers && data.covers.map(cover => (
                  <ImageCard
                    key={cover.id}
                    onClick={() => onPick(cover)}
                    dangerouslySetInnerHTML={{ __html: cover.svg }}
                  />
                ))}
              </CardGrid>
            )}
          </Content>
        </Surface>
      )}
    />
  );
}
