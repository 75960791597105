import React from 'react';
import styled from 'styled-components';
import { ProfilePic } from './Content';
import format from 'date-fns/format';
import { ReactComponent as ChevronRight } from '../icons/ChevronRight.svg';

const Row = styled.div`
  width: 100%;
  display: grid;
  padding: 12px 24px 12px 24px;
  grid-template-columns: 1fr 5fr auto;
  align-items: center;
  grid-gap: 0.625rem;
  border-bottom: 0.5px solid ${props => props.theme.surfaceLine};
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const Subtitle = styled.div`
  font-size: 14px;
  color: #333;
  line-height: 20px;
`

const AccessoryRowWrapper = styled.div`
  width: 100%;
  display: grid;
  color: ${props => props.color || '#442C2E'};
  grid-template-columns: auto 60px;
  align-items: center;
  padding: 12px 0 12px 20px;
  border-bottom: 0.5px solid ${props => props.theme.surfaceLine};
  xheight: 4em;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

export const AccessoryRow = ({ text = '', accessory, ...props }) => (
  <AccessoryRowWrapper {...props}>
    <Text>{text}</Text>
    {accessory}
  </AccessoryRowWrapper>
);

export const NavRow = ({ icon, ...props }) => (
  <AccessoryRow
    {...props}
    accessory={
      <AccessoryButton
        style={{ justifySelf: 'end', fill: 'rgba(0, 0, 0, 0.4)' }}
        icon={icon || <ChevronRight />}
      />
    }
  />
);

// TODO use Button
const AccessoryButton = ({ icon, color, style, ...props }) => (
  <button style={{ padding: '0 1em', ...style }} {...props}>
    {icon}
  </button>
);

const TwoColumnRow = styled(Row)`
  grid-template-columns: 80px auto 50px;
  grid-gap: 10px;
`;

export const ProfileRow = ({ name, imageUrl, ...props }) => (
  <TwoColumnRow {...props}>
    <ProfilePic imageUrl={imageUrl} name={name} small />
    <Text>{name}</Text>
    <ChevronRight style={{ justifySelf: 'end' }} />
  </TwoColumnRow>
);

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PostLabels = ({ name, subtext }) => (
  <Column>
    <Text> {name}</Text>
    <Subtitle> {subtext} </Subtitle>
  </Column>
)

const SvgCard = styled.div`
  background: grey;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  width: 40px;
  height: 40px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const PostRow = ({
 ...props
}) => (
  <Row {...props}>
    <SvgCard dangerouslySetInnerHTML={{ __html: props.cover && props.cover.svg }} />
    <PostLabels  {...props} />
  </Row>
);

export const PersonLabels = ({ firstName, lastName, subtext }) => (
  <Column>
    <Text>{firstName} {lastName}</Text>
    <Subtitle> {subtext} </Subtitle>
  </Column>
)

export const PersonRow = ({
  button,
  ...props
}) => (
  <Row {...props}>
    <ProfilePic small {...props} />
    <PersonLabels {...props} />
    {button ? button : null}
  </Row>
);

export const PersonActivity = ({ createdAt, person }) => {
  const timeStamp = format(createdAt, 'MMMM Do YYYY, hh:mm a');
  return (
  <Column>
    <Text>{person ? person.name : `Anonymous`}</Text>
    <Subtitle> {timeStamp} </Subtitle>
  </Column>
)}

export const ActivityRow = ({
  ...props
}) => (
  <Row {...props}>
    <ProfilePic small {...props.person} />
    <PersonActivity {...props} />
  </Row>
);

const Text = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #333;
`;

export const VersionRow = styled.div`
  width: 100%;
  padding-top: 32px;
  text-align: center;
  font-size: 0.7em;
`;

export const RowText = styled.div`
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.25);
  padding: 1rem;
`;
