import React from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import { Text, PlatformShareIcon } from './Content';
import { Button } from './Controls';

const ShareSheetContainer = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 0px;
  width: 100%;

  xmax-width: 500px;
  margin: 0 auto;

  xmin-height: 230px;
  padding: 20px 20px;
  border-radius: 10px 10px 0px 0px;
  background: #fff;
  box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.2);
  Button {
    width: 100%;
  }
  div {
    margin: 20px 0;
  }
`;

export function ShareSheet({ url, onDone }) {
  const copy = (value) => {
    const field = document.createElement('input');
    document.body.appendChild(field);
    field.setAttribute('value', value);
    field.select();
    document.execCommand('copy');
    document.body.removeChild(field);
  }

  return <ShareSheetContainer>
    <Text center bold>Share to</Text>
    <div><Button nav icon={<PlatformShareIcon />} onClick={() => { copy(url); onDone();}}><Trans>Copy Link</Trans></Button></div>
    <Button onClick={onDone}>Cancel</Button>
  </ShareSheetContainer>
}
