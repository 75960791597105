import React, { useEffect } from 'react';
import { Mutation } from '@apollo/react-components';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Trans } from '@lingui/macro';
import { ToastConsumer } from 'react-toast-notifications';
import { Form, Fieldset, ImageFieldset } from '../components/Forms';
import { NavBar } from '../components/NavBar';
import { Surface, ContentInset } from '../components/Layouts';
import { Button, Loading } from '../components/Controls';
import { ProfilePic, Text } from '../components/Content';
import { profileFragment } from '../utils/fragments';

const ProfileQuery = gql`
  ${profileFragment.profile}
  query($username: String!) {
    me {
      id
    }
    profile(username: $username) {
      ...ProfileFields
    }
  }
`;

const UpdateProfile = gql`
  mutation(
    $id: ID!
    $name: String!
    $bio: String!
    $imageUrl: String!
    $websiteUrl: String!
  ) {
    updateProfile(
      id: $id
      name: $name
      bio: $bio
      imageUrl: $imageUrl
      websiteUrl: $websiteUrl
    ) {
      id
      bio
      name
      imageUrl
      websiteUrl
    }
  }
`;

const ProfileForm = ({ values, onSubmit }) => (
  <Formik
    initialValues={values}
    enableReinitialize={true}
    onSubmit={onSubmit}
    validationSchema={Yup.object().shape({
      name: Yup.string().required(<Trans>Required</Trans>)
    })}
  >
    {({ isSubmitting, dirty }) => (
      <Form>
        <ImageFieldset name="imageUrl" id="imageUrl" component={ProfilePic} />
        <Fieldset name="name" label=<Trans>Display name</Trans> />
        <Fieldset name="bio" label=<Trans>Bio</Trans> />
        <Fieldset name="websiteUrl" label=<Trans>Website</Trans> />
        <Button disabled={!dirty || isSubmitting}>
          <Trans>Save</Trans>
        </Button>
      </Form>
    )}
  </Formik>
);

export default function ProfileEdit({ match, history }) {
  useEffect(() => { window.analytics.page('ProfileEdit') }, []);
  const { username } = match.params;
  const { loading, data, error } = useQuery(ProfileQuery, { variables: { username }});
  return (
    <Surface>
      <NavBar
        left={<Button nav close to={`/settings`} history={history} />}
        fixed
      >
        <Text bold>
          <Trans>Edit Profile</Trans>
        </Text>
      </NavBar>
      {loading && <Loading inset />}
      <ContentInset>
        <Mutation mutation={UpdateProfile}>
          {mutate => (
            <ToastConsumer>
              {({ add }) => (
                <>
                  {!loading &&
                    !error && (
                      <ProfileForm
                        loading={loading}
                        values={data.profile}
                        onSubmit={(variables, { setSubmitting }) => {
                          mutate({
                            variables
                          })
                            .then(result => {
                              setSubmitting(false);
                              history.push(`/settings`);
                              add(<Trans>Saved</Trans>, {
                                appearance: 'success',
                                autoDismiss: true
                              });
                            })
                            .catch(res => {
                              const errors = res.graphQLErrors.map(
                                error => {
                                  return add(error.message, {
                                    appearance: 'error',
                                    autoDismiss: true
                                  });
                                }
                              );
                              if (errors.length > 0) {
                                console.log('unhandled error');
                              }
                              setSubmitting(false);
                            });
                        }}
                      />
                    )}
                </>
              )}
            </ToastConsumer>
          )}
        </Mutation>
      </ContentInset>
    </Surface>
  );
}
