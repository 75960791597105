/* global localStorage */
import React, { useEffect, useRef, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import { ToastConsumer } from 'react-toast-notifications';
import { Link } from 'react-router-dom';
import { I18n } from '@lingui/react';
import { t } from '@lingui/macro';
import { ButtonNewsletter } from '../components/Forms';
import { Button } from '../components/Controls';
import { PlatformShareIcon } from '../components/Content';
import { Container } from '../components/Marketing';
import { InfoText, CenterSoundWave, NavMarketing, SignIn, StyledLine, InfoBox, InfoHeading, ContentInset, SectionY, HeroTitle, HeroDescription, ButtonCenter, ButtonText, HeroItems, ImageDiv, Section, ThinText, ThinTextHover, TextHover, RightWrapper, RightJustifyText, Wrapper, LeftWrapper, LeftJustifyText, BoldText } from '../components/Landing';
import { ReactComponent as Logo } from '../icons/Logo.svg';
import { ReactComponent as BarChart } from '../icons/bar-chart.svg';
import { ReactComponent as Mic } from '../icons/mictwo.svg';
import { ReactComponent as Users } from '../icons/users.svg';
import { ReactComponent as SoundWave } from '../icons/Sound.svg';
import { ReactComponent as AlignText } from '../icons/AlignText.svg';

const ListSubscribeQuery = gql`
  mutation($email: String!, $anonymousId: String!) {
    subscribe(email: $email, anonymousId: $anonymousId) {
      id
    }
  }
`;

const HeroSection = ({ history, children }) => {
  const [height, setHeight] = useState(0);

  const ref = useRef();

  useEffect(() => {
    setHeight(ref.current.clientHeight);

    function handleResize() {
      setHeight(ref.current.clientHeight);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ContentInset>
      <Section>
        <div ref={ref}>
          <InfoBox>
            <Logo />
            <HeroItems>
              <HeroTitle>
                <AlignText />
              </HeroTitle>
              <HeroDescription>
                {children}
              </HeroDescription>
              <ButtonCenter>
                <Button
                  height={'40px'}
                  to="/signup"
                  history={history}
                  primary
                  small
                >
                  <ButtonText>
                    Sign Up
                  </ButtonText>
                </Button>
              </ButtonCenter>
            </HeroItems>
          </InfoBox>
        </div>
        <ImageDiv height={height} />
      </Section>
    </ContentInset>
  )
};

const FeaturesSection = () => (
  <ContentInset background="#fafafa">
    <Section>
      <InfoBox>
        <Mic />
        <div>
          <InfoHeading>Teach in a connected world</InfoHeading>
          <InfoText>
            The intersection of yoga and technology is powerful—audio yoga classes are an inclusive and accessible way to practice. Align puts you at this junction and helps you reach more students.
          </InfoText>
        </div>
      </InfoBox>
      <InfoBox nopadding>
        <PlatformShareIcon stroke="#333" width="36" height="36" />
        <div>
          <InfoHeading>Create shareable classes</InfoHeading>
          <InfoText>
            Build an audio library without any extra time investment by recording the audio of your actual private or group classes—share classes on any messaging app, in your newsletter, on your website or on social media.
          </InfoText>
        </div>
      </InfoBox>
      <InfoBox>
        <BarChart />
        <div>
          <InfoHeading>Nourish your business</InfoHeading>
          <InfoText>
            Did you know that by recording just one audio class you can optimize your time and reach? A single class can be shared and practiced an infinite number of times.
          </InfoText>
        </div>
      </InfoBox>
      <InfoBox nopadding>
        <Users />
        <div>
          <InfoHeading>Build community</InfoHeading>
          <InfoText>
            What does your community of practice look like? Add new dimensions to your community engagement by connecting with your students online and on the mat.
          </InfoText>
        </div>
      </InfoBox>
    </Section>
  </ContentInset>
)

const NewsletterSection = () => {
  const ref = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(ref.current.clientWidth)

    function handleResize() {
      setWidth(ref.current.clientWidth)
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ContentInset ref={ref}>
      <SectionY>
        <InfoBox>
          <div />
          <div>
            <InfoHeading>
              <Trans>Newsletter</Trans>
            </InfoHeading>
            <InfoText>
              <Trans>Inspiration for your teaching practice</Trans>
            </InfoText>
            <div style={{
              marginTop: '16px'
            }}>
              <ListSubscribe />
            </div>
          </div>
        </InfoBox>
        <CenterSoundWave width={width}>
          <SoundWave />
        </CenterSoundWave>
      </SectionY>
    </ContentInset>
  )
};

function ListSubscribe(props) {
  return (
    <ToastConsumer>
      {({ add }) => (
        <I18n>
          {({ i18n }) => (
            <Mutation mutation={ListSubscribeQuery}>
              {(mutate, { loading, error }) => (
                <Formik
                  initialValues={{ email: '' }}
                  onSubmit={({ email }, { resetForm }) => {
                    const anonymousId = window.analytics.user().anonymousId();
                    mutate({ variables: { email, anonymousId } }).then(
                      result => {
                        console.log(result);
                        add(i18n._(t`You're on the list`), {
                          appearance: 'success',
                          autoDismiss: true
                        });
                        resetForm();
                      }
                    );
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email('Invalid')
                      .required('Required')
                  })}
                >
                  {props => (
                    <Form>
                      <ButtonNewsletter
                        name="email"
                        placeholder={i18n._(t`Email`)}
                        disabled={loading}
                      />
                    </Form>
                  )}
                </Formik>
              )}
            </Mutation>
          )}
        </I18n>
      )}
    </ToastConsumer>
  );
}

export const Footer = () => (
  <ContentInset background="#000000">
    <Wrapper>
      <LeftWrapper>
        <LeftJustifyText>
          <BoldText>Align</BoldText>
          <Link to="/signup">
            <TextHover> <Trans> Sign Up </Trans></TextHover>
          </Link>
          <a href="https://medium.com/align-labs">
            <TextHover> <Trans> Blog </Trans></TextHover>
          </a>
          <a href="mailto:hello@align.yoga">
            <TextHover> <Trans> Contact </Trans></TextHover>
          </a>
        </LeftJustifyText>
        <RightJustifyText>
          <BoldText> <Trans> Follow </Trans></BoldText>
          <a href="https://www.instagram.com/align.yoga/">
            <TextHover> <Trans> Instagram </Trans></TextHover>
          </a>
          <a href="https://www.facebook.com/alignyogaapp">
            <TextHover> <Trans> Facebook </Trans></TextHover>
          </a>
          <a href="https://twitter.com/align_labs">
            <TextHover> <Trans> Twitter </Trans> </TextHover>
          </a>
        </RightJustifyText>
      </LeftWrapper>
      <RightWrapper>
        <ThinText>Copyright © 2019</ThinText>
        <ThinText>Align Labs, Inc.</ThinText>
        <ThinText>All rights reserved</ThinText>
        <Link to="/terms"> <ThinTextHover inline> Terms of Service &nbsp; &nbsp; </ThinTextHover> </Link>
        <Link to="/privacy"> <ThinTextHover inline> Privacy Policy  </ThinTextHover> </Link>
      </RightWrapper>
    </Wrapper>
  </ContentInset>
);

export default function Landing({ history }) {
  useEffect(() => {
    window.analytics.page('Landing');
  }, []);
  return (
    <Container>
      <NavMarketing>
        <Link to="/signin">
          <SignIn>Sign In</SignIn>
        </Link>
      </NavMarketing>
      <HeroSection history={history}>
        <Trans>Curious about what audio yoga classes can do for your teaching practice?</Trans>
      </HeroSection>
      <StyledLine hide={true} />
      <FeaturesSection />
      <StyledLine />
      <NewsletterSection />
      <Footer />
    </Container>
  );
}

export function LandingGuide({ history }) {
  useEffect(() => {
    window.analytics.page('Landing');
  }, []);
  return (
    <Container>
      <NavMarketing>
        <Link to="/signin">
          <SignIn>Sign In</SignIn>
        </Link>
      </NavMarketing>
      <HeroSection history={history}>
        <Trans>Are you curious how to create a personal and shareable audio library for all your meditation and mindfulness classes?</Trans>
      </HeroSection>
      <StyledLine hide={true} />
      <FeaturesSection />
      <StyledLine />
      <NewsletterSection />
      <Footer />
    </Container>
  );
}
