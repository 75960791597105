/* global analytics */
/* global localStorage */
import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { I18n } from '@lingui/react';
import { t, Trans } from '@lingui/macro';
import { Form, LabeledField, FormHelp } from '../components/Forms';
import { Surface, ContentInset, StackCenter } from '../components/Layouts';
import { NavBar } from '../components/NavBar';
import { Button } from '../components/Controls';
import { PrimaryButton } from '../components/PrimaryButton';
import { Text } from '../components/Content';
import { signedIn } from '../utils/ApolloClient';
import { ReactComponent as LogoWordmark } from '../icons/LogoWordmark.svg';

const ACCESS_TOKEN = 'access_token';

export const SignUpQuery = gql`
  mutation($email: String!, $firstName: String!, $lastName: String!, $instagramUsername: String!, $note: String!) {
    signUp(email: $email, firstName: $firstName, lastName: $lastName, instagramUsername: $instagramUsername, note: $note) {
      token
      person {
        id
        email
        firstName
        lastName
      }
    }
  }
`;
function SignUp({ history }) {
  useEffect(() => { window.analytics.page('SignUp') }, []);
  if (signedIn()) {
    return <Redirect to="/posts" />;
  }
  return (
    <Mutation mutation={SignUpQuery}>
      {mutate => (
        <I18n>
          {({ i18n }) => (
            <Surface>
              <NavBar
                fixed
                left={<Button nav close to="/home" history={history} />}
              >
                <Text bold>
                  <Trans>Sign Up</Trans>
                </Text>
              </NavBar>
              <ContentInset>
                <div style={{marginTop: "48px"}} />
                <StackCenter>
                  <LogoWordmark />
                </StackCenter>
                <Text center marginBottom="40px" marginTop="16px">  
                  The best place for yoga instructors to continually connect with their students 
                </Text>
                <Formik
                  initialValues={{ email: '', firstName: '', lastName: '', instagramUsername: '', note: '' }}
                  onSubmit={({ email, firstName, lastName, instagramUsername, note }, { setSubmitting }) => {
                    setSubmitting(true);
                    analytics.track('signin-email-submitted', { email });
                    localStorage.setItem('email', email);
                    // console.log({ email, firstName, lastName, instagramUsername, note });
                    mutate({ variables: { email, firstName, lastName, instagramUsername, note }})
                      .then(result => {
                        setSubmitting(false);
                        localStorage.setItem(ACCESS_TOKEN, result.data.signUp.token);
                        window.analytics.identify(result.data.signUp.person.id, {
                          email: result.data.signUp.person.email,
                          firstName: result.data.signUp.person.firstName,
                          lastName: result.data.signUp.person.lastName,
                        });
                        history.replace('/posts');
                      })
                      .catch(err => {
                        setSubmitting(false);
                        // TODO
                      });
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email(i18n._(t`Invalid`))
                      .required(i18n._(t`Required`))
                  })}
                  render={({ isSubmitting }) => (
                    <Form>
                      <LabeledField
                        type="text"
                        name="firstName"
                        label={i18n._(t`First name`)}
                        placeholder={i18n._(t`First name`)}
                        required
                        autoFocus
                      />
                      <LabeledField
                        type="text"
                        name="lastName"
                        label={i18n._(t`Last name`)}
                        placeholder={i18n._(t`Last name`)}
                        required
                      />
                      <LabeledField
                        type="email"
                        name="email"
                        label={i18n._(t`Email`)}
                        placeholder={i18n._(t`Email`)}
                        required
                      />
                      <LabeledField
                        type="text"
                        name="note"
                        label={i18n._(t`Optional: How did you hear about Align?`)}
                        style={{marginBottom:'2em'}}
                        placeholder={i18n._(t`Name of person, IG, Google search`)}
                      />
                      <PrimaryButton type="submit" disabled={isSubmitting}>
                        <Trans>Next</Trans>
                      </PrimaryButton>
                      <FormHelp>
                        <Text small center>
                          <Trans> Have an account? 
                            <Link to="/signin">
                              Sign In
                            </Link>
                          </Trans>
                        </Text>
                      </FormHelp>
                    </Form>
                  )}
                />
              </ContentInset>
            </Surface>
          )}
        </I18n>
      )}
    </Mutation>
  );
}

export default SignUp;
