import React from 'react';
import styled from 'styled-components';
import ContentEditable from 'react-contenteditable';
import { Text, PlatformShareButton } from './Content';
import { ReactComponent as ImageSearch } from '../icons/ImageSearch.svg';
import { ReactComponent as CheckOn } from '../icons/CheckOn.svg';
import { ReactComponent as PlayButton } from '../icons/PlayButton.svg';

const PostWrapper = styled.div`
  box-shadow: inset 0 2px 10px rgba(255, 255, 255, 0.5),
    0 2px 15px rgba(0, 0, 0, 0.15);
  background: white;
  border-radius: 10px;
  border: ${props =>
    props.notSelectable
      ? `4px solid ${props.theme ? props.theme.notSelectableColor : 'grey'}`
      : props.selected
        ? `4px solid ${props.theme ? props.theme.selectedBorderColor : 'grey'}`
        : 'none'};
  display: grid;
  grid-template-rows: 50% 50%;
  min-width: 140px;
  width: 100%;
  max-width: 190px;
`;

const TopHalf = styled.div`
  grid-area: 1 / 1 / span 1 / span 1;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
  width: 100%;
  max-width: 190px;
`;

const BottomHalf = styled.div`
  grid-area: 2 / 1 / span 1 / span 1;
  min-width: 0;
  min-height: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  max-width: 190px;
`;

const Cover = styled.div`
  width: 100%;
  border-radius: 10px 10px 0 0;
  background-color: darkGrey;
  background-image: ${props => (props.css ? props.css : `url(${props.url})`)};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: rgba(255, 255, 255, 0.8);
    filter: drop-shadow(2px 0px 15px rgba(0, 0, 0, 0.5));
    height: 30px;
    width: 30px;
    margin-bottom: 35px;
  }
`;

const Center = styled.div`
  grid-area: 1 / 1 / span 2 / span 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EditableName = styled(ContentEditable)`
  padding: 0;
  margin: 20px 0 0 0;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.2em;
  text-align: center;
  width: 100%;
  min-height: 40px;
  height: 100%;
  border: 1px solid ${props => (props.disabled ? 'none' : '#ccc')};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  word-wrap: break-word;
`;

const Check = styled(CheckOn)`
  position: absolute;
  bottom: 5%;
  right: 5%;
  height: 24px;
  width: 24px;
`;

export const Post = React.memo(
  ({
    selected,
    notSelectable,
    id,
    editing,
    onClick,
    onCoverClick,
    onSave,
    updating,
    name,
    cover,
    contentUrl,
    durationSec,
    progress,
    buttons,
    ...rest
  }) => (
    <PostWrapper
      onClick={editing ? null : onClick}
      selected={selected}
      notSelectable={notSelectable}
    >
      <Center>
        {buttons}
      </Center>
      <TopHalf>
        <Cover {...cover} onClick={onCoverClick}>
          {editing && <ImageSearch />}
        </Cover>
      </TopHalf>
      <BottomHalf>
        <EditableName
          html={name || ' '}
          disabled={!editing}
          onBlur={onSave}
        />
        {notSelectable || selected ? (
          <Check fill={selected ? '#219653' : '#ae3427'} />
        ) : null}
      </BottomHalf>
    </PostWrapper>
  )
);

const PostCardWrapper = styled.div`
  background: white;
  border-radius: 10px;
  border: 1px solid #DADADA;
  display: grid;
  grid-template-rows: 1fr 1fr;
`;

const CoverContainer = styled.div`
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;
  border-radius: 10px 10px 0px 0px;
  background-color: darkGrey;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 132px; /* 112 per spec */
  xmin-height: 152px;
  margin-bottom: 0px;
  display: flex;
  overflow: hidden;
  xsvg {
    overflow: hidden;
  }
`;

export const PostText = styled.div`
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 2;
  display: grid;
  align-content: center;
  justify-content: center;
  margin: 24px 10px 0px 10px;
  grid-gap: 8px;
`;

const ButtonContainer = styled.div`
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 2;
  margin: auto;
`;

export function Duration({ durationSec }) {
  const durationMin = Math.floor(durationSec / 60);
  return <Text center>{durationSec < 200 ? `${durationSec} sec` : `${durationMin} min`}</Text>
}

export const PostCard = ({ name, cover, media, viewerCanEdit, onShare }) => (
  <PostCardWrapper>
    <CoverContainer dangerouslySetInnerHTML={{ __html: cover.svg }} />
    <PostText>
      <Text center bold lineHeight="20px">{name}</Text>
      <Duration {...media} />
      {/*<Text center>{media.creator.name}</Text>*/}
    </PostText>
    <ButtonContainer>
      {viewerCanEdit ? (
        <PlatformShareButton onClick={onShare} />
      ) : (
        <PlayButton />
      )}
    </ButtonContainer>
  </PostCardWrapper>
);
