import React, { Suspense, lazy } from 'react';
import { Route, Router, Switch, withRouter } from 'react-router-dom';
import { createBrowserHistory } from "history";
import Landing, { LandingGuide } from './pages/Landing';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import Verify from './pages/Verify';
import Home from './pages/Home';
import Waitlist from './pages/Waitlist';
import Settings from './pages/Settings';
import Profile from './pages/Profile';
import ProfileNew from './pages/ProfileNew';
import ProfileEdit from './pages/ProfileEdit';
import Posts from './pages/Posts';
import People from './pages/People';
import Person from './pages/Person';
import PostNew from './pages/PostNew';
import Post from './pages/Post';
import PostEdit from './pages/PostEdit';
import Covers from './pages/Covers';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Help from './pages/Help';
import { EmptyPage } from './components/Layouts';
import ErrorPage from './pages/ErrorPage';
const LazyDev = lazy(() => import('./pages/Dev'));

const Dev = props => (
  <Suspense fallback={<EmptyPage />}>
    <LazyDev {...props} />
  </Suspense>
);

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

const ScrollUp = withRouter(ScrollToTop);

export const AppRoutes = React.memo(() => {
  return (
    <Router history={createBrowserHistory()}>
      <ScrollUp>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/dev" component={Dev} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/landing" component={Landing} />
          <Route exact path="/guide" component={LandingGuide} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/apply" component={SignUp} />
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/verify" component={Verify} />
          <Route exact path="/covers" component={Covers} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/new-profile" component={ProfileNew} />
          <Route exact path="/new" component={PostNew} />
          <Route path="/posts" component={Posts} />
          <Route exact path="/people" component={People} />
          <Route exact path="/people/:personId" component={Person} />
          <Route exact path="/waitlist" component={Waitlist} />
          <Route exact path="/error" component={ErrorPage} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/help" component={Help} />
          <Route exact path="/p/:postId" component={Post} />
          <Route exact path="/p/:postId/edit" component={PostEdit} />
          <Route exact path="/:username/posts" component={Posts} />
          <Route exact path="/:username/post/:postId" component={Post} />
          <Route exact path="/:username/edit" component={ProfileEdit} />
          <Route exact path="/:username" component={Profile} />
        </Switch>
      </ScrollUp>
    </Router>
  );
});
