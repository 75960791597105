import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Redirect, Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import format from 'date-fns/format';
import { Trans } from '@lingui/macro';
import { Loading, HelpButton, SettingsButton } from '../components/Controls';
import { PrimaryButton } from '../components/PrimaryButton';
import { Text } from '../components/Content';
import { ShareSheet } from '../components/ShareSheet';
import { SectionHeader } from '../components/Text';
import { PostCard } from '../components/Post';
import { FooterButton } from '../components/FooterButton';
import { NavBar } from '../components/NavBar';
import { ContentInset, Surface, CardGrid, Stack } from '../components/Layouts';
import { NullState } from '../components/Content';
import { postFragment } from '../utils/fragments';
import { ReactComponent as Mic } from '../icons/Mic.svg';
import { CreatePost } from './PostNew';

export const PostsQuery = gql`
  ${postFragment.post}
  query {
    me {
      id
      waitlisted
      posts {
        ...PostFields
      }
      profiles {
        id
      }
      currentProfile {
        id
        username
      }
      teachers {
        id 
        name
        username
        posts {
          ...PostFields
        }
      }
    }
  }
`;

function PostsGroup({ posts=[], title, children, onShare, ...rest }) {
  if (posts.length === 0) return children || null;
  return (
    <React.Fragment {...rest}>
      <SectionHeader>{title}</SectionHeader>
      <CardGrid>
        {posts.map(post => (
          <Link to={post.url} key={post.id}>
            <PostCard
              {...post}
              onShare={(e) => { e.preventDefault(); onShare(post); } }
            />
          </Link>
        ))}
      </CardGrid>
    </React.Fragment>
  )
}

function NewPostButton({ personId }) {
  const name = format(new Date(), 'MMMM Do');
  const [createPost, { data, loading, called }] = useMutation(CreatePost);
  if (called && !loading) {
    return <Redirect to={`/p/${data.createPost.id}/edit`} />
  }
  return (
    <FooterButton padding>
      <PrimaryButton 
        disabled={!personId || loading} 
        onClick={() => {
          createPost({
            variables: { 
              name,
            },
          });
        }} 
      >
        <Trans>Create Class</Trans>
      </PrimaryButton>
    </FooterButton>
  )
}

function sharePost(post, setSharePostUrl, setFallback) {
  if (navigator.share) {
    navigator.share({
      title: post.name,
      url: `https://align.yoga${post.url}`,
    }).then(() => {
      // TODO track
    })
    .catch(console.error);
  } else {
    setSharePostUrl(post.url);
    setFallback(true);
  }
}

const Posts = props => {
  useEffect(() => { window.analytics.page('Posts') }, []);
  const [showShareSheet, setShowShareSheet] = useState(false);
  const [sharePostUrl, setSharePostUrl] = useState(null);

  const { loading, error, data } = useQuery(PostsQuery);

  return (
    <>
      <Surface>
        <NavBar left={<HelpButton />} right={<SettingsButton />}>
          <Text large bold><Trans>Classes</Trans></Text>
        </NavBar>
        <ContentInset>
          {loading && <Loading />}
          {!loading && !error && data.me.waitlisted && <Redirect to="/waitlist" />}
          {!loading && !error && <PostsGroup posts={data.me.posts} title={"My classes"} onShare={(post) => sharePost(post, setSharePostUrl, setShowShareSheet)}>
            <Stack>
              <NullState icon={<Mic />}>
                <Text center marginTop={'24px'}>Welcome to your classes! </Text>
                <Text center>Classes you create will be added here.</Text>
                <Text center>Check out our 
                  <Link to="/help"> Quick Start Guides </Link> if you are new to audio recording.</Text>
              </NullState>
            </Stack>
          </PostsGroup>}
          {!loading && !error && data.me.teachers.map(teacher => (
            <PostsGroup key={teacher.id} posts={teacher.posts} title={teacher.name} />
          ))}
          <div style={{ height:20 }} />
        </ContentInset>
        <NewPostButton personId={!loading && !error && data.me.id} />
      </Surface>
      {showShareSheet && <ShareSheet
        url={`https://align.yoga${sharePostUrl}`}
        onDone={() => setShowShareSheet(false)}
      />}
    </>
  )
};

export default Posts;
