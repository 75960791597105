import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { withApollo } from '@apollo/react-hoc';
import { Mutation } from '@apollo/react-components';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import debounce from 'lodash/debounce';
import { Trans } from '@lingui/macro';
import { ToastConsumer } from 'react-toast-notifications';
import { NavBar } from '../components/NavBar';
import { Form, Fieldset, Group } from '../components/Forms';
import { Surface, ContentInset } from '../components/Layouts';
import { Button } from '../components/Controls';
import { PrimaryButton } from '../components/PrimaryButton';
import { Text } from '../components/Content';

const MeQuery = gql`
  query {
    me {
      firstName
      lastName
      instagramUsername
      instagramInfo {
        name
        websiteUrl
        bio
        imageUrl
      }
    }
  }
`;

const NewProfileMutation = gql`
  mutation($name: String!, $username: String!) {
    createProfile(name: $name, username: $username) {
      id
      name
      username
      imageUrl
    }
  }
`;

const usernameQuery = gql`
  query($username: String!) {
    profile(username: $username) {
      id
      name
      username
    }
  }
`;

const useUsernameAvailability = ({client, username }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const debounceQuery = debounce(async () => {
    setLoading(true);
    const { data } = await client.query({query: usernameQuery, variables: { username }})
    setLoading(false);
    if (data && data.profile && data.profile.username) {
      setError(data.profile.username) 
    } else {
      setError(null)
    }
  }, 150);

  useEffect(() => {
    if (username) {
      debounceQuery();
    }
    return () => debounceQuery.cancel();
  }, [username])

   return [error, loading];
}

const UsernameField = withApollo(({ validateField, client, ...props }) => {
  const [error, loading]  = useUsernameAvailability({
      client, 
      username: props.values.username
  });

  useEffect(() => {
    if (props.values.username) {
      validateField(props.name);
    }
  }, [error])

  return (
    <Fieldset 
      {...props} 
      loading={loading}
      validate={() => props.validate(error)}
    />
  )
});

const validateUsername = (username) => (usrnameError) => {
  if (!username) { return <Trans>Required</Trans> }
  let inputStringLength = username.length;
  if (inputStringLength < 4) { return <Trans>Must be at least 4 characters</Trans> }
  if (inputStringLength > 15) { return <Trans>Must be at most 15 characters</Trans> }
  if (!/^[a-zA-Z0-9_ .-]*$/.test(username)) { return <Trans>Must not contain special characters</Trans> } 
  if (!/^[A-Za-z0-9_.-]*$/.test(username)) { return <Trans>Must not contain spaces</Trans> } 

  if (usrnameError) {
    return  <> <Trans> Username taken: </Trans> {usrnameError} </>
  }
  return null;
}

const NewProfileForm = ({ values, mutate, onSuccess }) => (
  <Formik
    initialValues={values}
    enableReinitialize={true}
    onSubmit={(variables, { setSubmitting, resetForm }) => {
      mutate({ variables })
        .then(result => {
          setSubmitting(false);
          resetForm();
          onSuccess(result.data.createProfile);
        })
        .catch(res => {
          const errors = res.graphQLErrors.map(error => error.message);
          if (errors.length > 0) {
            console.log('unhandled error');
          }
          setSubmitting(false);
        });
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string()
        .required('Required'),
    })}
  >
    {({ isSubmitting,  values, errors, validateField }) => (
      <Form>
        <Fieldset
          name="name"
          errors={errors}
          label={<Trans>Display Name</Trans>}
          placeholder="Yoga with Sarah"
        />
        <UsernameField 
          type="text"
          name="username"
          label="username"
          values={values} 
          errors={errors}
          placeholder="sarah"
          validate={validateUsername(values.username)}
          validateField={validateField}
        />
        <Group>
          <PrimaryButton type="submit" disabled={isSubmitting}>
            <Trans>Next</Trans>
          </PrimaryButton>
        </Group>
      </Form>
    )}
  </Formik>
);

function defaultUsername(data) {
  if (data && data.me) {
    if (data.me.instagramUsername) {
      return data.me.instagramUsername;
    } 
    
    if (data.me.firstName) {
      return `${data.me.firstName.toLowerCase()}`;
    }
  }
  return '';
}

function defaultName(data) {
  if (data && data.me) {
    if (data.me.instagrameInfo) {
      return data.me.instagramInfo.name;
    } else {
      return `${data.me.firstName} ${data.me.lastName}`;
    }
  }
  return '';
}

export default function ProfileNew({ match, history }) {
  useEffect(() => { window.analytics.page('ProfileNew') }, []);
  const { data } = useQuery(MeQuery);
  return (
    <Surface>
      <NavBar
        left={
          <Button nav close to={`/home`} history={history} />
        }
        transparent
      >
        <Text bold>
          <Trans>Teacher Profile</Trans>
        </Text>
      </NavBar>
      <ContentInset>
        <Mutation mutation={NewProfileMutation}>
          {mutate => (
            <ToastConsumer>
              {({ add }) => (
                <NewProfileForm
                  values={{
                    name: defaultName(data),
                    username: defaultUsername(data),
                  }}
                  mutate={mutate}
                  onSuccess={(profile) => {
                    history.push(`/posts`);
                  }}
                />
              )}
            </ToastConsumer>
          )}
        </Mutation>
      </ContentInset>
    </Surface>
  );
}
