import React from 'react';
import styled from 'styled-components';
import { Button as BaseButton } from './Controls';

export function Button(props) {
  return <BaseButton primary {...props} />;
}

export const Container = styled.div`
  font-size: 15px;
  color: #777;
  fill: #444;
  a {
    text-decoration: none;
  }
  h3 {
    font-size: 1.3em;
    font-weight: 700;
    color: #666;
    margin: 0;
    padding: 0;
  }
  footer {
    display: flex;
    justify-content: space-between;
    margin-top: 5em;
    a {
      color: #777;
      font-size: 0.8em;
      font-weight: 400;
      text-transform: uppercase;
    }
  }
`;

export const Inset = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding: 2em;
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Hero = styled.section`
  xbackground-image: linear-gradient(to top, #9890e3 0%, #b1f4cf 100%);
  xbackground-image: linear-gradient(to top, #9795f0 0%, #fbc8d4 100%);
  background-image: linear-gradient(to top, #88d3ce 0%, #6e45e2 100%);
  background-size: cover;
  background-attachment: fixed;
  margin: 3em -1em 3em -1em;
  padding: 3em 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 2em;

  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);

  h1 {
    font-size: 2.5em;
    font-weight: 800;
    xmargin: 2em 0;
    margin: 0 0 1em 0;
    padding: 0;
    color: white;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  }

  h3 {
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 1em;
    color: white;
    
  }

  p {
    text-align: left;
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.1em;
    margin: 0 0 0.5em 0;
    text-align: left;
  }
  
  button {
    margin-top: 1em;
  }
`;

export const Boxes = styled.section`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  h3 {
    xtext-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    margin: 0;
    padding: 0;
  }
  p {
    margin: 0;
    padding: 0;
  }
`;

export const Box = styled.div``;