import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { NavBar } from '../components/NavBar';
import { Button } from '../components/Controls';
import { Surface, ContentInset, Stack } from '../components/Layouts';
import { Trans } from '@lingui/macro';
import { Text } from '../components/Content';
import { Form, TextArea } from '../components/Forms';
import { I18n } from '@lingui/react';
import { t } from '@lingui/macro';
import { goBackOrHome } from '../components/utils';

const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`;

const StyledText = styled(Text)`
  text-decoration: underline;
  text-underline-position: under;
`;

const ContactUs = gql`
  mutation contactUs($message: String!, $anonymousId: String) {
    contactUs(message: $message, anonymousId: $anonymousId)
  }
`;

const ListElement = props => (
  <li>
    <a href={props.to} target="_blank" rel="noopener noreferrer">
      <StyledText>
        <Trans id={props.text} />
      </StyledText>
    </a>
  </li>
);

const Upper = () => (
  <div>
    <Text large bold color="#000">  
      <Trans>Getting Started</Trans>
    </Text>
    <List>
      <ListElement to="https://medium.com/align-labs/quick-start-guide-to-recording-and-sharing-audio-yoga-63c07ed5c87b" text="Quick start guide to using Align" />
      <ListElement to="https://medium.com/align-labs/recording-audio-on-a-smartphone-be9c8455ae3c" text="Guide for voice recording" />
      <ListElement to="https://medium.com/align-labs/how-to-record-great-audio-on-your-phone-a-guide-for-yoga-teachers-22939aa55f4b" text="Best practices for audio based yoga" />
    </List>
  </div>
);

const Lower = () => (
  <Stack>
    <Text large bold color="#000">  
      <Trans>Contact Us</Trans>
    </Text>
    <Text>
      <Trans>
        We're here to help. Let us know what's tripping you up or what you love!
      </Trans>
    </Text>
    <HelpForm />
  </Stack>
);

const HelpForm = () => {
  const [msgSent, setMsgSent] = useState(false);
  const [contactUs] = useMutation(ContactUs);
  return (<>
    { msgSent ? <div style={{ marginTop: '2em'}}> Thanks for your message! </div> :
      <Formik onSubmit={ async ( { message }, { setSubmitting, resetForm }) => { 
          setSubmitting(true);
          try {
            await contactUs({ variables: { message } })
          } catch(err) {
            console.log('Error:', err)
          }
          setSubmitting(false);
          setMsgSent(true);
          resetForm();
        }}>
        {({ isSubmitting }) => (
          <I18n>
            {({ i18n }) => (
              <Form>
                <TextArea
                  type="text"
                  name="message"
                  placeholder={i18n._(t`What's the best way to record?`)} 
                  component={"textarea"}
                />
                <Button
                  primary
                  type="submit"
                  disabled={isSubmitting}
                >
                  <Trans>Send</Trans>
                </Button>
              </Form>
            )}
          </I18n>
        )}
      </Formik>
    }
  </>)
};

const Help = () => {
  useEffect(() => { window.analytics.page('Help') }, []);
  return (
    <Surface>
      <NavBar
        left={<Button secondary nav close onClick={() => goBackOrHome(true)} />}
        center={
          <Text bold>
            <Trans>Help Center</Trans>
          </Text>
        }
      />
      <ContentInset padding="74px 24px 80px 24px">
        <Stack height="auto" gridgap="30px">
          <Upper />
          <Lower />
        </Stack>
      </ContentInset>
    </Surface>
  )
};

export default Help;
