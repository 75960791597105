//import React from 'react';
import styled from 'styled-components';

export const SectionHeader = styled.h3`
  margin-top: 24px;
  margin-bottom: 16px;
  margin-left: ${props => props.marginLeft || '0px'};
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: -0.02em;
  color: #333333;
`;

export const Text = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: -0.02em;
  color: #333333;
`;
