/* global analytics */
/* global localStorage */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { I18n } from '@lingui/react';
import { t, Trans } from '@lingui/macro';
import { Redirect } from 'react-router';
import { Form, LabeledField, FormHelp, FormHeading} from '../components/Forms';
import { Surface, ContentInset } from '../components/Layouts';
import { NavBar } from '../components/NavBar';
import { Button } from '../components/Controls';
import { PrimaryButton } from '../components/PrimaryButton';
import { Text } from '../components/Content';
import { RequestSigninCode } from './SignIn';
import { signedIn } from '../utils/ApolloClient';

const ACCESS_TOKEN = 'access_token';

const SignIn = gql`
  mutation($email: String!, $verificationCode: String!) {
    signIn(email: $email, verificationCode: $verificationCode) {
      token
      person {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

function Verify({ auth, history, location }) {
  const [hasError, setHasError] = useState(false);
  const [resentCode, setResentCode] = useState(false);
  useEffect(() => { window.analytics.page('Verify') }, []);
  const [ mutate ] = useMutation(SignIn)
  const [ reSendCode ] = useMutation(RequestSigninCode)
  if (signedIn()) {
    return <Redirect to="/posts" />;
  }

  return (
    <I18n>
      {({ i18n }) => (
        <Surface>
          <NavBar
            fixed
            transparent
            left={
              <Button nav back to="/signin" history={history} />
            }
            center={<Text big bold><Trans>Verification</Trans></Text>}
            right={<div />}
          />
          <ContentInset>
            <Formik
              initialValues={{ code: '' }}
              onSubmit={({ code }, { setSubmitting }) => {
                setSubmitting(true);
                if(hasError) {
                  setHasError(false)
                };
                if (resentCode) {
                  setResentCode(false);
                };
                analytics.track('signin-code-submitted');
                if (location.state && location.state.email) {
                  // Note: invokes passwordless login, which will call the /callback route
                  mutate({ variables: { email: location.state.email, verificationCode: code }})
                    .then(result => {
                      setSubmitting(false);
                      //console.log(`got token ${result.data.signIn.token}`)
                      localStorage.setItem(ACCESS_TOKEN, result.data.signIn.token);
                      window.analytics.identify(result.data.signIn.person.id, {
                        email: result.data.signIn.person.email,
                        firstName: result.data.signIn.person.firstName,
                        lastName: result.data.signIn.person.lastName,
                      });
                      history.replace('/posts');
                    })
                    .catch(err => {
                      setSubmitting(false);
                      if (err) {
                        setHasError(true);
                      }
                    });
                  // auth
                  //   .verifyCode(location.state.email, code, this.getAuthResults)
                  //   .then(() => {
                  //     setSubmitting(false);
                  //   })
                  //   .catch(err => {
                  //     setSubmitting(false);
                  //     this.showError(err);
                  //   });
                } else {
                  auth
                    .verifyCodeSMS(location.state.phoneNumber, code, this.getAuthResults)
                    .then(() => {
                      setSubmitting(false);
                    })
                    .catch(err => {
                      setSubmitting(false);
                      if (err) {
                        setHasError(true);
                      }
                    });
                }
              }}
              validationSchema={Yup.object().shape({
                code: Yup.string()
                  .min(5, i18n._(t`5 numbers`))
                  .required(i18n._(t`Required`))
              })}
              render={({ isSubmitting }) => (
                <Form>
                  {location.state && location.state.phoneNumber &&
                    <FormHelp>
                      <Trans>
                        Check your messages for the verification code
                      </Trans>
                    </FormHelp>
                  }
                  {location.state && location.state.email &&
                    <FormHeading>
                      <Text>
                        <Trans
                          id="We've emailed {email}. Check your inbox for the verifcation code."
                          values={{ email:location.state.email }}
                        />
                      </Text>
                    </FormHeading>
                  }
                  <div style={{ height: '20px ' }} />
                  <LabeledField
                    type="text"
                    pattern="[0-9]*"
                    name="code"
                    label={i18n._(t`Verification code`)}
                    placeholder="12345"
                    autoComplete="off"
                    required
                    autoFocus
                    huge="true"
                  />
                  <PrimaryButton type="submit" disabled={isSubmitting}>
                    <Trans>Next</Trans>
                  </PrimaryButton>
                  <FormHelp>
                    {hasError && (
                    <Text small center>
                      <Trans>Invalid code or expired</Trans>
                    </Text>
                    )}
                    {resentCode && (
                      <Text small center>
                        <Trans>Resent the code!</Trans>
                      </Text>
                    )}
                  </FormHelp>
                </Form>
              )}
            />
            <Text small center>
            <Trans>
              Didn't recieve it? 
            </Trans> 
            {` `}
            <button onClick={() => {
              analytics.track('resend-code-submitted');
              if (location.state.email !== '') {
                reSendCode({ variables: { email: location.state.email } })
                .then(result => {
                  if(hasError) {
                    setHasError(false)
                  }
                  setResentCode(true)
                })
                .catch(res => {
                  res.graphQLErrors
                    ? res.graphQLErrors.map(err =>
                        console.log(err.message)
                      )
                    : console.log(`Error : ${res}`);
                });  
              } 
            }}>
              <strong>
                <Trans>{` `}Resend code</Trans>
              </strong>
            </button>
            </Text>
          </ContentInset>
        </Surface>
      )}
    </I18n>
  );
}
      
export default Verify;
