/* global localStorage */
import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Trans, Plural } from '@lingui/macro';
import { NavBar } from '../components/NavBar';
import { ProfilePic, Text } from '../components/Content';
import { ContentInset, Surface, StackCenter } from '../components/Layouts';
import { PostRow } from '../components/Rows';
import { HelpButton, SettingsButton } from '../components/Controls';

export const PersonQuery = gql`
  query($id: ID!) {
    person(id: $id) {
      id
      name
      imageUrl
      personActivity: activity {
        id
        post {
          id
          name
          cover {
            id
            svg
          }
          listenCount(personId: $id)
        }
      }
    }
  }
`;

const Person = React.memo(props => {
  const { match } = props;
  const { personId } = match.params;
  const { loading, data, error } = useQuery(PersonQuery, { variables: { id: personId }});
  var postArray = [];

  return (
    <Surface>
      <NavBar left={<HelpButton />} right={<SettingsButton />}>
        <Text large bold><Trans>Student Details</Trans></Text>
      </NavBar>
      <ContentInset padding="84px 0px 80px 0px">
        { !loading && !error && 
          <>
            <StackCenter>
              <ProfilePic {...data.person} />
              <div>
                <Text lineHeight={"29px"} extraLarge bold center> {data.person.name} </Text>
                <Text lineHeight={"29px"} center> 
                  {data.person.personActivity.length} {` `}
                  <Plural
                    value={data.person.personActivity.length}
                    one="Listen"
                    other="Listens"
                  />  
                </Text>
              </div>
            </StackCenter>
            <div style={{paddingTop: '24px'}} />
              {
                data.person.personActivity.map(personActivity => {
                  if (postArray.findIndex((id) => id === personActivity.post.id) === -1) {
                    postArray.push(personActivity.post.id);
                    return (
                      <PostRow
                        key={personActivity.post.id}
                        subtext={
                          <> 
                            {personActivity.post.listenCount} {` `}
                            <Plural
                              value={personActivity.post.listenCount}
                              one="Listen"
                              other="Listens"
                            /> 
                          </>
                        }
                        {...personActivity.post}
                      />
                    )  
                  }
                  return null;
                })
              }
          </>  
        }
      </ContentInset>
    </Surface>
  );
});

export default Person;
