import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { ToastProvider } from 'react-toast-notifications';
import { useQuery } from '@apollo/react-hooks';
import { I18nProvider } from '@lingui/react';
import gql from 'graphql-tag';
import { normalize } from 'styled-normalize'
import catalogs from './locales';
import { Toast, CustomToastContainer, UploadingStatus, ToastAPIErrors } from './components/Toast';
import DocumentTitle from 'react-document-title';
import { defaultTheme } from './themes';
import { useUploadMediaContent } from './utils/useUploadMediaContent';

export const GlobalStyle = createGlobalStyle`
${normalize}
* {
  box-sizing: border-box;
}

:focus:not(:focus-visible) {
  outline: none;
}

:focus { 
  outline:none;
}

html, body, #root {
  user-select: none;
  height: 100%;
}

body {
  margin-top: env(safe-area-inset-top);
  margin-left: env(safe-area-inset-left);
  margin-bottom: env(safe-area-inset-bottom);
  margin-right: env(safe-area-inset-right);
  
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

a {
  color: #444;
  font-weight: bold;
  text-decoration: none;
}

ul {
  margin-left: -15px;
}

/* for animationExample */
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

/* Reset button */
button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
}
`;

export const MediaUploadContext = React.createContext();

export const PreferredLanguage = gql`
  query PreferredLanguage {
    preferredLanguage @client
  }
`;

export default function App({ children }) {
  const { data } = useQuery(PreferredLanguage)
  const [onFiles, media, setMedia, progress, complete, uploading ] = useUploadMediaContent();
  return (
    <I18nProvider
      catalogs={catalogs}
      language={data.preferredLanguage || 'en'}
    >
      <GlobalStyle />
      <ToastProvider placement="bottom-center" components={{ Toast, ToastContainer: CustomToastContainer }}>
        <ToastAPIErrors />
        <MediaUploadContext.Provider value={{setMedia, onFiles, progress, complete}}>
          { media && <UploadingStatus media={media} progress={progress} complete={complete} uploading={uploading} /> }
          <ThemeProvider theme={defaultTheme}>
            <DocumentTitle title="Align">{children}</DocumentTitle>
          </ThemeProvider>
        </MediaUploadContext.Provider>
      </ToastProvider>
    </I18nProvider>
  );
}

App.propTypes = {
  children: PropTypes.element.isRequired
};
