/*global localStorage*/
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, Observable } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import { typeDefs } from './resolvers';

export const ACCESS_TOKEN_KEY = 'access_token';

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};

export function signOut() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export function signedIn() {
  let token = localStorage.getItem(ACCESS_TOKEN_KEY);
  return !!token;
}

const cache = new InMemoryCache({
  cacheRedirects: {
    Query: {
      media: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'Media', id: args.id }),
      post: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'Post', id: args.id }),
      profile: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'Profile', id: args.id }),
      person: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'Person', id: args.id })
    }
  }
});

const httpLink = createHttpLink({
  uri: 'https://align.sh'
  //uri: 'http://localhost:3000'
  // credentials: 'same-origin',
  // fetchOptions: {
  //   mode: 'no-cors',
  // },
});

const request = async operation => {
  let headers = {
    'content-type': 'application/json',
  };
  const accessToken = getAccessToken();
  if (accessToken) {
    headers['authorization'] = `Bearer ${accessToken}`;
  } else {
    if (window.analytics.user) {
      headers['anonymousId'] = window.analytics.user().anonymousId();
    }
  }
  operation.setContext({ headers });
};

// Apollo link for Auth
const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

// Apollo link for network status
const {
  NetworkStatusNotifier,
  link: networkStatusNotifierLink
} = createNetworkStatusNotifier();

const link = ApolloLink.from([
  networkStatusNotifierLink,
  requestLink,
  httpLink
]);

const client = new ApolloClient({
  link,
  cache,
  defaultHttpLink: false,
  typeDefs
});

cache.writeData({
  data: {
    preferredLanguage: 'en' // TODO look at accept header?
  }
});

export const NetworkStatus = NetworkStatusNotifier;

export default client;
