import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { NavBar } from '../components/NavBar';
import { Surface, ContentInset, Stack } from '../components/Layouts';
import { Trans } from '@lingui/macro';
import { Loading, SettingsButton } from '../components/Controls'
import { NullState, Text } from '../components/Content';

export const Query = gql`
  query {
    me {
      id
      waitlisted
    }
  }
`;

export default function Waitlist() {
  useEffect(() => { window.analytics.page('Waitlist') }, []);

  const { loading, data, error } = useQuery(Query);

  return (
    <Surface>
      <NavBar fixed
        right={<SettingsButton />}
        center={<Text bold>Early Access Program</Text>}
      />
      <ContentInset>
        <Stack>
          {!loading && !error && !data.me.waitlisted && <Redirect to="/posts" />}
          <NullState>
            <Text center small marginTop={'24px'}>
              <Trans>Thanks for applying for Early Access. We'll let you know when your account is ready.</Trans>
            </Text>
            {loading && <Loading inset={false} />}
          </NullState>
        </Stack>
      </ContentInset>
    </Surface>
  );
}
