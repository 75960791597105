/* global navigator */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Trans } from '@lingui/macro';
import { Media, Player } from 'react-media-player';
import { Cover } from '../components/Cover';
import { Duration, PostText } from '../components/Post';
import { ShareSheet } from '../components/ShareSheet';
import { Surface, ContentInset } from '../components/Layouts';
import { FooterButton } from '../components/FooterButton';
import { PrimaryButton } from '../components/PrimaryButton';
import { NavBar } from '../components/NavBar';
import { Button, Loading, PlayerControls } from '../components/Controls';
import { ActivityRow } from '../components/Rows';
import { SectionHeader, Text, PlatformShareIcon, ProfilePic } from '../components/Content';
import {
  postFragment,
} from '../utils/fragments';
import { ReactComponent as EditIcon } from '../icons/EditIcon.svg';
import { ReactComponent as Close } from '../icons/Close.svg';
import { ReactComponent as Pause } from '../icons/Pause.svg';
import { ReactComponent as PlayButton } from '../icons/PlayButton.svg';

export const PostQuery = gql`
  ${postFragment.post}
  query($url: String!) {
    post(url: $url) {
      ...PostFields
    }
  }
`;

const PostActivityQuery = gql`
  query($url: String!) {
    post(url: $url) {
      id
      postActivity: activity {
        id
        createdAt
        person {
          id
          name
          imageUrl
        }
      }
    }
  }
`;

const LogPractice = gql`
  mutation logPractice($postId: ID!) {
    logPractice(postId: $postId) {
      id
    }
  }
`;

function sharePost(post, setFallback) {
  if (navigator.share) {
    navigator.share({
      title: post.name,
      url: `https://align.yoga${post.url}`,
    }).then(() => {
      // TODO track
    })
    .catch(console.error);
  } else {
    setFallback(true);
  }
}

const PostsButton = () => (
  <Link to="/posts">
    <Button iconButton icon={<Close height="24" width="24" stroke="#FFFFFF" />} />
  </Link>
)

const EditButton = ({ url }) => (
  <Link to={`${url}/edit`}>
    <Button iconButton icon={<EditIcon height="24" width="24" stroke="#FFFFFF" />} />
  </Link>
)

const ShareButton = (props) => (
  <Button iconButton icon={<PlatformShareIcon height="24" width="24"  stroke="#FFFFFF"/>}
    {...props}
  />
)

function PostAdmin({ loading, data, error, id, url, setShowShareSheet }) {
  //const activityResult = useQuery(PostActivityQuery, { variables : { id }});
  const {
    loading: activityLoading,
    data: { post: { postActivity } = {} }
  } = useQuery(PostActivityQuery, { variables: { url } });
  return (
    <Surface>
      <NavBar fixed transparent
        left={<PostsButton />}
        right={<EditButton url={url} />}
      />
      <Media key={id}>
        {({ isPlaying, playPause }) => (
          <>
            <ContentInset padding="0" style={{ marginTop: -60 }}>
              <Cover {...data.post.cover } height={"300px"} />
              <div style={{margin:"-30px auto 0 auto", width:"56px", zIndex:2, position:"relative"}}>
                <Player src={data.post.media.contentUrl} vendor="audio" />
                <Button
                  iconButton
                  onClick={playPause}
                  icon={isPlaying ? <Pause height="56" width="56"/> : <PlayButton height="56" width="56"/>}
                  height={"56px"}
                />
              </div>
              <PostText big style={{marginTop: "20px"}}>
                <Text lineHeight={"29px"} extraLarge bold center> {data.post.media.name}</Text>
              </PostText>
              <Duration {...data.post.media} />
              {(activityLoading || postActivity.length < 1) ? <div /> : (
                <>
                  <SectionHeader style={{ marginTop: 50 }}><Trans>Activity</Trans></SectionHeader>
                  {postActivity.map(act => (
                    <ActivityRow key={act.id} {...act} />
                  ))}
                </>
              )}
            </ContentInset>
            <FooterButton padding>
              <PrimaryButton onClick={() => sharePost(data.post, setShowShareSheet)}>
                <Trans>Share</Trans>
              </PrimaryButton>
            </FooterButton>
          </>
        )}
      </Media>
    </Surface>
  )
}

function PostConsume({ id, data, loading, error, setShowShareSheet }) {
  const { name, creator } = !loading && data.post.media;
  const cover = !loading && data.post.cover;
  const [logPractice] = useMutation(LogPractice);
  return (
    <Surface>
      <NavBar
        transparent
        left={false ? <PostsButton /> : null}
        right={<ShareButton onClick={() => sharePost(data.post, setShowShareSheet)} />}
      />
      <ContentInset padding="0" style={{ marginTop: -60 }}>
        <Cover {...cover} height={"324px"} />
        {loading ? <Loading /> :
          <>
            <div style={{margin:"-60px auto 0 auto", width:"120px", zIndex:2, position:"relative"}}>
              <ProfilePic src={data.post && data.post.media.creator.imageUrl}/>
            </div>
            <div style={{margin: '32px 0px'}}> 
              <PostText big>
                <Text lineHeight={"29px"} extraLarge bold center>{name}</Text>
              </PostText>
              <Text center>{creator.name}</Text>
            </div>
            <PlayerControls
              src={data.post.media.contentUrl}
              postId={data.post.id}
              onStartPlaying={() => {
                logPractice({ variables: { postId: data.post.id } })
              }}
            />
            { data.post.media.creator.bio && 
            <>
              <Text large bold marginLeft="24px" marginTop="48px">  
                <Trans>About {data.post.media.creator.firstName}</Trans>
              </Text>
              <Text marginLeft="24px">  
                <Trans>{data.post.media.creator.bio}</Trans>
              </Text>
            </>
            }
          </>
        }
      </ContentInset>
    </Surface>
  )
}

export default function Post({ location }) {
  useEffect(() => { window.analytics.page('Post') }, []);
  const [showShareSheet, setShowShareSheet] = useState(false);
  const url = location.pathname;
  const { loading, data, error } = useQuery(PostQuery, { variables : { url }})
  const adminMode = !loading && !error && data.post.viewerCanEdit && location.hash!=='#preview';

  return <>
    {adminMode
      ? <PostAdmin url={url} loading={loading} error={error} data={data} setShowShareSheet={setShowShareSheet} />
      : <PostConsume url={url} loading={loading} error={error} data={data} setShowShareSheet={setShowShareSheet} />
    }
    {showShareSheet && <ShareSheet
      url={`https://align.yoga${!loading && data.post.url}`}
      onDone={() => setShowShareSheet(false)}
    />}
  </>;
}
