import gql from 'graphql-tag';

export const personFragment = {
  person: gql`
    fragment PersonFields on Person {
      __typename
      id
      homeUrl
      name
      firstName
      lastName
      email
      bio
      location
      imageUrl
      phoneNumber
      instagramUsername
    }
  `
};

export const coverFragment = {
  cover: gql`
    fragment CoverFields on Cover {
      __typename
      id
      svg
    }
  `
};

export const mediaFragment = {
  media: gql`
    ${personFragment.person}
    fragment MediaFields on Media {
      __typename
      id
      name
      contentUrl
      description
      durationSec
      creator {
        ...PersonFields
      }
    }
  `
};

// profile {
//   id
//   username
// }
// activity {
//   id
//   createdAt
// }
export const postFragment = {
  post: gql`
    ${mediaFragment.media}
    ${coverFragment.cover}
    fragment PostFields on Post {
      __typename
      id
      url(relative:true)
      name
      viewerCanEdit
      cover {
        ...CoverFields
      }
      media {
        ...MediaFields
      }
    }
  `
};

export const profileFragment = {
  profile: gql`
    fragment ProfileFields on Profile {
      __typename
      id
      name
      username
      bio
      imageUrl
      websiteUrl
      viewerCanEdit
    }
  `
};