import styled from 'styled-components';
import { device } from './Layouts';

//The 24px left/right padding is coming from the default ContentInset
export const FooterButton = styled.footer`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px 24px;
  justify-content: space-around;

  position: fixed;
  bottom: 24px;
  left: 0;

  @media ${device.mobileL} {
    position: relative;
    padding: ${(props) => props.padding ? `0px 24px` : `0`};
    bottom:  ${(props) => props.padding ? `24px` : `auto`};
    left: auto;
  }
`;

