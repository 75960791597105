import React from 'react';
import styled from 'styled-components';

const NavBarWrapper = styled.header``;

const NavBarContainer = styled.div`
  height: 60px;
  transition: 0.2s;
  background: ${props =>
    props.transparent || props.scroll ? 'clear' : props.theme.navBarBackground};
  display: grid;
  grid-template-columns: minMax(4em, auto) 1fr auto minMax(4em, auto);
  justify-items: center;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid ${props => props.transparent ? 'clear' : 'rgba(0, 0, 0, 0.1)'};
`;

export const NavBar = ({
  left,
  center,
  right,
  secondaryRight,
  fixed,
  transparent,
  scroll,
  children
}) => (
  <NavBarWrapper>
    <NavBarContainer fixed={fixed} transparent={transparent} scroll={scroll}>
      {left || <div />}
      {children || center || <div />}
      {secondaryRight || <div />}
      {right || <div />}
    </NavBarContainer>
  </NavBarWrapper>
);