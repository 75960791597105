import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { Media, Player } from 'react-media-player';
import * as Yup from 'yup';
import { I18n } from '@lingui/react';
import { t, Trans } from '@lingui/macro';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { UploadField } from '@navjobs/upload';
import { Cover } from '../components/Cover';
import { NavBar } from '../components/NavBar';
import { Surface, ContentInset } from '../components/Layouts';
import { Button } from '../components/Controls';
import { PrimaryButton } from '../components/PrimaryButton';
import { Text } from '../components/Content';
import { LabeledField } from '../components/Forms';
import { Duration } from '../components/Post';
import { postFragment, mediaFragment } from '../utils/fragments';
import { ReactComponent as Back } from '../icons/Back.svg'
import { PostsQuery } from './Posts';
import Covers from './Covers';
import { ReactComponent as Pause } from '../icons/Pause.svg';
import { ReactComponent as PlayButton } from '../icons/PlayButton.svg';
import { MediaUploadContext } from '../App.js';

export const PostQuery = gql`
  ${postFragment.post}
  query($url: String!) {
    post(url: $url) {
      ...PostFields
    }
  }
`;

export const MediaQuery = gql`
  ${mediaFragment.media}
  query($id: ID!) {
    media(id: $id) {
      ...MediaFields
    }
  }
`;

const UpdatePost = gql`
  ${postFragment.post}
  mutation(
    $id: ID!
    $name: String
    $coverId: ID
    $mediaId: ID
  ) {
    updatePost(
      id: $id
      name: $name
      coverId: $coverId
      mediaId: $mediaId
    ) {
      ...PostFields
    }
  }
`;

const DeletePost = gql`
  mutation($id: ID!) {
    deletePost(id: $id) {
      id
    }
  }
`;

const Stack = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ gridgap }) => (gridgap ? gridgap : '10px')};
  width: 100%;
`;

function PickPostCover({ post, updatePost, setPickingCover }) {
  return <Covers
    onCancel={() => setPickingCover(false)}
    onPick={(newCover) => {
      updatePost({
        variables: { id: post.id, coverId: newCover.id },
        optimisticResponse: {
          __typename: 'Mutation',
          updatePost: {
            __typename: 'Post',
            id: post.id,
            name: post.name,
            cover: newCover,
          }
        },
      });
      setPickingCover(false);
    }}
  />;
}

const PostEdit = ({ history, location }) => {
  const url = location.pathname;
  useEffect(() => { window.analytics.page('PostEdit') }, []);
  const [pickingCover, setPickingCover] = useState(false);
  const { data, error, loading } = useQuery(PostQuery, { variables: { url }});
  const [deletePost, { loading: deleting }] = useMutation(DeletePost, {
    refetchQueries: [{
      query: PostsQuery
    }]
  });
  const [updatePost, { loading: updating }] = useMutation(UpdatePost, {
    refetchQueries: [{
      query: PostsQuery
    }]
  });

  const post = data && data.post; // TODO no
  const media = post && data.post.media; // TODO no
  const cover = post && data.post.cover; // TODO no

  if (error) return <p>Error</p>;

  if (pickingCover) {
    return <PickPostCover
      post={post}
      setPickingCover={setPickingCover}
      updatePost={updatePost}
    />;
  }

  const contentUrl = media && media.contentUrl;
  return <Surface>
    <NavBar
      fixed
      left={
        <Link to={media && media.contentUrl ? post.url : `/posts`}>
          <Button
            iconButton
            icon={<Back height="24" width="24"/>}
          />
        </Link>
      }      
    >
      <Text large bold> { media && media.contentUrl ? 'Edit Class' : 'Create Class'} </Text>
    </NavBar>
    <ContentInset>
      <Cover big={true} {...cover} onClick={() => { setPickingCover(true) }} height={"180px"}>
        <Text color="#ffffff" bold> Choose cover </Text>
      </Cover>
      <MediaUploadContext.Consumer>
        {({setMedia, onFiles, progress, complete}) => {
          setMedia(media);
          const uploading = progress && !complete;
          return (
            <Media>
              {({ isPlaying, playPause }) => (
                <Stack style={{marginTop:-28, zIndex:2, position:"relative"}}>
                  <Player src={contentUrl} vendor="audio" />
                  {contentUrl && <Button
                    iconButton
                    onClick={playPause}
                    icon={isPlaying ? <Pause height="56" width="56"/> : <PlayButton height="56" width="56"/>}
                    height={"56px"}
                  />}
                  {contentUrl && <Duration {...media} />}
                  {contentUrl && <UploadField onFiles={onFiles} uploadProps={{ accept: 'audio/*' }} >
                    {progress && !complete ? <Text center> Uploading audio ... </Text> : <Text bold center> <Trans> Replace Audio </Trans> </Text>}
                  </UploadField>}
                  {!contentUrl &&
                    <UploadField onFiles={onFiles} uploadProps={{ accept: 'audio/*' }} >
                      <Button dark fat width={'100%'}>
                        {uploading ? `Uploading audio ...` : <Trans>Upload audio</Trans>}
                      </Button>
                    </UploadField>
                  }
                  {!contentUrl && <Text center> Need help recording? </Text>}
                  {!contentUrl && <Text bold center> 
                    <a 
                      href="https://medium.com/align-labs/how-to-record-great-audio-on-your-phone-a-guide-for-yoga-teachers-22939aa55f4b"
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      Check out some tips 
                    </a>
                    </Text>
                  }
                </Stack>
              )}
            </Media>
          )}}
      </MediaUploadContext.Consumer> 
      <div style={{height:30}} />
      <I18n>
        {({ i18n }) => (
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: post ? post.name : '',
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required(i18n._(t`Required`)),
            })}
            onSubmit={(values, { setSubmitting }) => {
              updatePost({
                variables: {
                  id: post.id,
                  name: values.name
                },
              }).then(() => {
                //console.log('saved')
                history.push('/posts');
              });
            }}
            render={({ isSubmitting, dirty }) => {
              return (
                <Form>
                  <LabeledField
                    name="name"
                    label={i18n._(t`Class name`)}
                    placeholder={i18n._(t`Vinayasa before breakfast`)}
                    required
                  />
                  <Stack gridgap={'18px'}>
                    <Button
                      disabled={deleting}
                      type="button"
                      secondary
                      onClick={() => {
                        console.log('the value of post is ', post);
                        deletePost({ variables: { id: post.id }})
                          .then(() => history.push(`/posts`));
                      }}
                    >
                      <Trans>Delete</Trans>
                    </Button>
                    <PrimaryButton type="submit" disabled={loading || updating}>
                      <Trans>Save</Trans>
                    </PrimaryButton>
                  </Stack>
                </Form>
              );
            }}
          />
        )}
      </I18n>
    </ContentInset>
  </Surface>;
};

export default PostEdit;
