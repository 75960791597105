import { useState } from 'react';
import gql from 'graphql-tag';
import { mediaFragment } from '../utils/fragments';
import { UploadRequest } from '@navjobs/upload';
import { MediaQuery } from '../pages/PostEdit'; // TODO bad
import { useMutation } from '@apollo/react-hooks';
import { PostsQuery } from '../pages/Posts';

const UpdateMedia = gql`
  ${mediaFragment.media}
  mutation(
    $id: ID!
    $name: String
    $description: String
    $contentUrl: String
    $coverId: ID
    $durationSec: Int
  ) {
    updateMedia(
      id: $id
      name: $name
      description: $description
      coverId: $coverId
      contentUrl: $contentUrl
      durationSec: $durationSec
    ) {
      ...MediaFields
    }
  }
`;

export const useUploadMediaContent =  () => {
  const [media, setMedia] = useState();
  const [progress, setProgress] = useState(0);
  const [complete, setComplete] = useState(false);
  const [updateMedia] = useMutation(UpdateMedia, {
    refetchQueries: [{
      query: PostsQuery
    }]
  });

  const onFiles = async (files) => {
    setComplete(false);
    const { response } =  await UploadRequest({
      request: {
        url: 'https://api2.transloadit.com/assemblies',
        method: 'POST',
        fields: {
          params: JSON.stringify({
            auth: {
              key: '79e28fb0ffd811e8abba8fe04eb746ff'
            },
            template_id: 'ca771220ffd811e8b7a04d3419af9088',
            notify_url: 'https://align.sh/transloadit'
          }),
          mediaId: media.id
        }
      },
      files, //files array
      progress: value => setProgress(value) ,
    }); 

    setComplete(true);
    console.log('response', response);
    const contentUrl = response.uploads[0].ssl_url;
    const durationSec = Math.ceil(response.uploads[0].meta.duration); 
    updateMedia({
      variables: { id: media.id, contentUrl, durationSec },
      optimisticResponse: {
        __typename: 'Mutation',
        updateMedia: {
          __typename: 'Media',
          id: media.id,
          name: media.name,
          description: media.description,
          contentUrl,
          durationSec
        }
      },
      update: (proxy, { data }) => {
        const record = proxy.readQuery({
          query: MediaQuery,
          variables: { id: media.id }
        });
        record.media.contentUrl = data.updateMedia && data.updateMedia.contentUrl;
        record.media.durationSec = data.updateMedia && data.updateMedia.durationSec;
        proxy.writeQuery({
          query: MediaQuery,
          data: record
        });
      }
    });
  }

  return [ onFiles, media, setMedia, progress, complete ];
}