import React from 'react';
import styled from 'styled-components';
import { device } from '../components/Layouts';

const CoverImage = styled.div`
  position: ${props => props.big ? 'absolute' : 'default'};
  border-radius: ${props => props.big ? null : '10px 10px 0px 0px' };
  background-color: darkGrey;
  hxeight: 300px;
  height: ${props => props.height ? props.height : '152px'};
  width: 100%;
  display: flex;
  @media ${device.mobileL} {
    max-width: 400px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

const CoverWrapper = styled.div`
  width: 100%;
  min-height: ${props => props.height ? props.height : '152px'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CoverText = styled.div`
  z-index: 1;
`;

export const Cover = ({onClick, svg, big, height, children}) => (
  <CoverWrapper onClick={onClick} height={height}>
    <CoverImage height={height} big={big} dangerouslySetInnerHTML={{ __html: svg }} />
    <CoverText>
      {children}
    </CoverText>
  </CoverWrapper>
)

export const CoverContainer = styled.div`
  width: 100%;
  height: 100%;
  svg {
    width: 100%;
    height: 100%;
  }
`;
