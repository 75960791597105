/* global document */
/* global analytics */
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
// import LogRocket from 'logrocket';
import client from './utils/ApolloClient';
import App from './App';
import { AppRoutes } from './AppRoutes';
import ErrorPage from './pages/ErrorPage';
import registerServiceWorker from './utils/registerServiceWorker';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.error(error, info);
    analytics.track('crash', {
      usermsg: error.toString(),
      info
      //stack: componentStack
    });
  }

  render() {
    return this.state.hasError
      ? (
        <App client={client}>
          <ErrorPage />
        </App>
      ) : this.props.children;
  }
}

ReactDOM.render(
  <ErrorBoundary>
    <ApolloProvider client={client}>
      <App>
        <AppRoutes />
      </App>
    </ApolloProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);

registerServiceWorker();
