/* global analytics */
/* global localStorage */
import React, { useEffect } from 'react';
import { Mutation } from '@apollo/react-components';
import { Formik } from 'formik';
import { Redirect } from 'react-router';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import { I18n } from '@lingui/react';
import { t, Trans } from '@lingui/macro';
import { Form, LabeledField, FormHelp } from '../components/Forms';
import { Surface, ContentInset } from '../components/Layouts';
import { NavBar } from '../components/NavBar';
import { Button } from '../components/Controls';
import { PrimaryButton } from '../components/PrimaryButton';
import { Text } from '../components/Content';
import { phoneRegExp } from '../utils/patterns';
import { signedIn } from '../utils/ApolloClient';

export const RequestSigninCode = gql`
  mutation($email: String!) {
    requestSigninCode(email: $email)
  }
`;

function SignIn({ history }) {
  useEffect(() => { window.analytics.page('SignIn') }, []);
  if (signedIn()) {
    return <Redirect to="/posts" />;
  }
  return (
    <Mutation mutation={RequestSigninCode}>
      {mutate => (
        <I18n>
          {({ i18n }) => (
            <Surface>
              <NavBar
                fixed
                transparent
                left={<Button nav close to={`/home`} history={history} />}
              >
                <Text bold>
                  <Trans>Sign In</Trans>
                </Text>
              </NavBar>
              <ContentInset>
                <Formik
                  initialValues={{
                    email: localStorage.getItem('email') || '',
                    phoneNumber: localStorage.getItem('phoneNumber') || ''
                  }}
                  onSubmit={({ email, phoneNumber }, { setSubmitting }) => {
                    setSubmitting(true);
                    if (email !== '') {
                      analytics.track('signin-email-submitted', { email });
                      localStorage.setItem('email', email);
                      mutate({ variables: { email } })
                        .then(result => {
                          setSubmitting(false);
                          history.replace('/verify', { email });
                        })
                        .catch(res => {
                          setSubmitting(false);
                          res.graphQLErrors
                            ? res.graphQLErrors.map(err =>
                                console.log(err.message)
                              )
                            : console.log(`Error : ${res}`);
                        });
                    } else {
                      analytics.track('signin-phone-submitted', { phoneNumber });
                      localStorage.setItem('phoneNumber', phoneNumber);
                      // TODO: sign in w phone
                      history.replace('/verify', { phoneNumber });
                    }
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().email(i18n._(t`Invalid`)),
                    phoneNumber: Yup.string()
                      .nullable()
                      .matches(phoneRegExp, i18n._(t`Invalid`))
                  })}
                  render={({ isSubmitting }) => (
                    <Form>
                      <LabeledField
                        type="email"
                        name="email"
                        label={i18n._(t`Email`)}
                        placeholder={i18n._(t`Email`)}
                        autoFocus
                      />
                      {false && (
                        <LabeledField
                          type="phone"
                          name="phoneNumber"
                          label={i18n._(t`Phone`)}
                          placeholder={i18n._(t`Phone`)}
                        />
                      )}
                      <PrimaryButton type="submit" disabled={isSubmitting}>
                        <Trans>Next</Trans>
                      </PrimaryButton>
                      <FormHelp>
                        <Text small center>
                          <Trans>We will send you a verification code</Trans>
                        </Text>
                      </FormHelp>
                    </Form>
                  )}
                />
              </ContentInset>
            </Surface>
          )}
        </I18n>
      )}
    </Mutation>
  );
}
      
export default SignIn;
