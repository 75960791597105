import React, { useState, useEffect, useRef } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Media, Player, controls, withMediaProps, utils } from 'react-media-player';
import { ReactComponent as Pause } from '../icons/Pause.svg';
import { ReactComponent as Done } from '../icons/Done.svg';
import { ReactComponent as Add } from '../icons/Add.svg';
import { ReactComponent as Delete } from '../icons/Delete.svg';
import { ReactComponent as Edit } from '../icons/Edit.svg';
import { ReactComponent as Close } from '../icons/Close.svg';
import { ReactComponent as LoadingImage } from '../icons/Loading.svg';
import { ReactComponent as ArrowForward } from '../icons/ArrowForward.svg';
import { ReactComponent as Back } from '../icons/Back.svg';
import { ReactComponent as Account } from '../icons/Account.svg';
import { ReactComponent as Settings } from '../icons/Settings.svg';
import { ReactComponent as Message } from '../icons/Message.svg';
import { ReactComponent as EditButton } from '../icons/EditButton.svg';
import { ReactComponent as SkipBack } from '../icons/SkipBack.svg';
import { ReactComponent as PlayButton } from '../icons/PlayButton.svg';
import { ReactComponent as SkipAhead } from '../icons/SkipAhead.svg';
import { ReactComponent as Person } from '../icons/Person.svg';
import { ReactComponent as Help } from '../icons/Help.svg';

// todo: refer to named theme colors
function buttonColor({ dark, primary, white, danger, disabled, play, secondary, tertiary }) {
  if (primary && disabled) return 'white';
  if (disabled) return '#D7D4D3';
  if (danger || primary || play || dark || white || tertiary) return 'white';
  if (secondary) return '#333';
  return '#676463';
}

// todo: refer to named theme colors
function buttonBackground({
  hover,
  dark,
  primary,
  danger,
  disabled,
  active,
  play,
  outline,
  secondary,
  tertiary
}) {
  if (primary && disabled) return 'rgba(67, 205, 163, 0.5)';
  if (disabled) return '#828282';
  if (danger && active) return '#BF5454';
  if (danger) return '#DC6060';
  if (primary && active) return '#1B7A44';
  if (primary && hover)
    return '#43CDA3';
  if (primary) return '#43CDA3';
  if (secondary) return '#fff';
  if (tertiary) return '#333';

  if (dark) return 'black';
  if (play && active) return '#333';
  if (play) return '#4F4F4F';
  if (active) return 'rgba(0, 0, 0, 0.1)';
  return 'transparent';
}

// todo: refer to named theme colors
function buttonBorder({
  fat,
  primary,
  dark,
  danger,
  disabled,
  active,
  nav,
  play,
  secondary,
  tertiary,
  iconButton
}) {
  if (fat) return '3px solid white';
  if (nav) return 'none';
  if (iconButton) return 'none';
  if (disabled) return 'none';
  if (play) return '1px solid white';
  if (danger) return 'none';
  if (primary) return 'none';
  if (dark) return 'black';
  if (active) return '1px solid #4F4F4F';
  if (secondary) return '1px solid #333333';
  if (tertiary) return '2px solid #FFFFFF';
  return '1px solid #D7D4D3';
}

function Icon(props) {
  if (props.loading) return <LoadingImage />;
  if (props.icon) return props.icon;
  if (props.done) return <Done />;
  if (props.close) return <Close />;
  if (props.add) return <Add />;
  if (props.delete) return <Delete />;
  if (props.edit) return <Edit />;
  if (props.account) return <Account />;
  if (props.forward) return <ArrowForward />;
  if (props.back) return <Back />;
  if (props.settings) return <Settings />;
  if (props.message) return <Message />;
  if (props.editbutton) return <EditButton />;
  if (props.playbutton) return <PlayButton />;

  if (Label(props)) return null;
  return <ArrowForward />; // Default to icon if no label
}
 
function Label(props) {
  if (props.children) return <span>{props.children}</span>;
  if (props.label) return <span>{props.label}</span>;
  return null; // no default text, because i18n
}

const StyledButton = styled.button`
  cursor: pointer;
  font-size: ${props => (props.small ? '14px' : '16px')};
  font-weight: bold;
  letter-spacing: -0.02em;
  min-height: ${props => props.height};
  min-width: ${props => props.width ? props.width : props.height};
  border-radius: ${props => props.height};
  border: ${props => buttonBorder(props)};
  color: ${props => buttonColor(props)};
  fill: ${props => buttonColor(props)};
  stroke: ${props => buttonColor(props)};

  background: ${props => buttonBackground(props)};
  box-shadow: ${props =>
    props.raised ? '-1px 17px 24px -6px rgba(0,0,0,.2)' : 'none'};
  &:active {
    background: ${props => buttonBackground({ ...props, active: true })};
    border: ${props => buttonBorder({ ...props, active: true })};
  }
  &:hover {
    background: ${props => buttonBackground({ ...props, hover: true })};
    border: ${props => buttonBorder({ ...props, hover: true })};
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > svg ~ span {
    padding: 0 20px 0 0;
  }
  & > svg {
    width: ${props => props.height};
  }
`;

// TODO
// - raise if type isn't set
// - require either onClick, to/history, or type=submit
export function Button({ big, small, onClick, to, history, ...props }) {
  // TODO: raise unless
  if (onClick === undefined && to && history) {
    onClick = () => history.push(to);
  }
  let height = '56px';
  if (big) height = '70px';
  if (small) height = '32px';
  return (
    <StyledButton small={small} onClick={onClick} height={props.height ? props.height : height} {...props}>
      <Icon {...props} />
      <Label {...props} />
    </StyledButton>
  );
}

const PlayableTimer = props => {
  const [time, setTime] = useState(0);
  const [startStop, setStartStop] = useState(false);
  const interval = useRef();

  const start = () => {
    if (startStop === true) return;
    setStartStop(true);
    restartInterval();
  };

  const stop = time => {
    clearInterval(interval.current);
    setStartStop(false);
    setTime(0);
  };

  //This function has closed over the intial state forever!
  const elapseTime = () => {
    setTime(time => {
      return time + 1;
    });
  };

  const format = seconds => {
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor((seconds % 3600) % 60);
    let timeFormated = (m < 10 ? '0' : '') + m + ':' + (s < 10 ? '0' : '') + s;
    return timeFormated;
  };

  const restartInterval = () => {
    clearInterval(interval.current);
    interval.current = setInterval(elapseTime, 1000);
  };

  useEffect(
    () => {
      if (props.play) {
        start();
      } else {
        stop();
      }
      return () => clearInterval(interval.current);
    },
    [props.play]
  );

  return (
    <div className={props.className}>
      <span>{format(time)}</span>
    </div>
  );
};

export const Timer = styled(PlayableTimer)`
  font-size: 4rem;
  display: flex;
  justify-content: center;
  color: black;
  // color: ${props => props.theme.iconColors};
`;

//Player Components

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  width: 100%;
  padding: 1rem;
`;

const TimeLayout = styled.div`
  color: #333;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const PlayerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0rem 3rem 0rem 3rem;
`;

export const SeekControl = styled(controls.SeekBar)`
    overflow: hidden;
    width: 100%;
    -webkit-appearance: none;
    background-color: #ccc;
    height: 4px;
    margin-bottom: 10px;
  &::-webkit-slider-runnable-track {
    height: 4px;
    -webkit-appearance: none;
  }
  &::-webkit-slider-thumb {
    width: 15px;
    -webkit-appearance: none;
    height: 15px;
    cursor: ew-resize;
    box-shadow: -405px 0 0 400px #333;
  }
`;

export const VolumeControl = styled(controls.Volume)`
    overflow: hidden;
    width: 100%;
    -webkit-appearance: none;
    background-color: #ccc;
    height: 4px;
    margin-bottom: 10px;
    margin-top: 48px;
  &::-webkit-slider-runnable-track {
    height: 4px;
    -webkit-appearance: none;
  }
  &::-webkit-slider-thumb {
    width: 15px;
    -webkit-appearance: none;
    height: 15px;
    cursor: ew-resize;
    background: none;
    box-shadow: -405px 0 0 400px #333;
  }
`;

const { formatTime } = utils;
export const SeekBar = ({ currentTime, duration }) => (
  <>
    <SeekControl />
    <TimeLayout>
      <span>  {formatTime(currentTime)} </span>
      <span> -{formatTime(duration - currentTime)} </span>
    </TimeLayout>
  </>
)

const CustomPlayPause = withMediaProps(props => {
  const { media, onStartPlaying } = props;
  const { seekTo, isPlaying, playPause, currentTime, duration } = media; // volume, setVolume

  return (
    <>
      <SeekBar currentTime={currentTime} duration={duration} />
      <PlayerButtons>
        <Button
          nav
          icon={<SkipBack width="32" height="37" fill="#333" />}
          onClick={() => {
            seekTo(currentTime - 30);
          }}
        />
        <Button
          iconButton
          icon={
            isPlaying ? (
              <Pause height="56" width="56" />
            ) : (
              <PlayButton height="56" width="56" />
            )
          }
          onClick={() => {
            if (!isPlaying && currentTime === 0) {
              onStartPlaying()
            }
            playPause()
          }}
          height={"56px"}
        />
        <Button
          nav
          icon={<SkipAhead width="32" height="37" fill="#333333" />}
          onClick={() => {
            seekTo(currentTime + 30);
          }}
        />
      </PlayerButtons>
    </>
  );
});

export const PlayerControls = ({ src, postId, onStartPlaying }) => (
  <Media>
    <Wrapper>
      <CustomPlayPause postId={postId} onStartPlaying={onStartPlaying} />
      <Player src={src} vendor="audio" />
    </Wrapper>
  </Media>
);

export const AnyPicker = props => {
  const { items, Component, handleSelect, notSelectable } = props;
  const [selectedIndexes, setSelectedIndexes] = useState([]);

  useEffect(
    () => {
      handleSelect(selectedIndexes);
    },
    [selectedIndexes]
  );

  const handleClick = (e, index, itemId) => {
    if (!notSelectable.includes(itemId)) {
      setSelectedIndexes(selectedIndexes => {
        if (selectedIndexes.indexOf(index) === -1) {
          return selectedIndexes.concat(index);
        }
        return selectedIndexes.filter(value => value !== index);
      });
    }
  };

  return (
    <>
      {items.map((item, index) => (
        <Component
          onClick={e => handleClick(e, index, item.id)}
          key={item.id}
          selected={selectedIndexes.includes(index)}
          notSelectable={notSelectable.includes(item.id)}
          {...item}
        />
      ))}
    </>
  );
};

export const LoadingSmall = LoadingImage;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => (props.inset ? '400px' : 'auto')};
`;

export const Loading = ({ loading = true, inset = true }) => {
  if (!loading) {
    return null;
  }
  return (
    <LoadingWrapper inset={inset}>
      <LoadingImage />
    </LoadingWrapper>
  );
};

const MediaInputQuery = gql`
  query($username: String!, $contentType: String!) {
    mediaInput(contentType: $contentType, username: $username) {
      signedUrl
      mediaUrl
      contentType
    }
  }
`;

export const SignedUrl = (props) => {
  const { username, contentType } = props;

  const { loading, data, error } = useQuery(MediaInputQuery, { variables: { username, contentType }});
  const mediaUrl = !loading && !error ? data.mediaInput.mediaUrl : null;
  const signedUrl = !loading && !error ? data.mediaInput.signedUrl : null;
  const contType = !loading && !error ? data.mediaInput.contentType : null;

  return props.children({
    loading,
    mediaUrl,
    signedUrl,
    contentType : contType,
  });
}

export const HelpButton = () => (
  <Link to="/help">
    <Button nav icon={<Help />} />
  </Link>
)

export const SettingsButton = () => (
  <Link to="/settings">
    <Button nav icon={<Person />} />
  </Link>
)
