import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { Trans, Plural } from '@lingui/macro';
import { Loading, Button } from '../components/Controls';
import { ProfileRow } from '../components/Rows';
import { NavBar } from '../components/NavBar'
import { Surface, ContentInset } from '../components/Layouts';
import { SectionHeader } from '../components/Content';
import Landing from './Landing';
import SignIn from './SignIn';
import { personFragment, profileFragment } from '../utils/fragments';
import { signedIn } from '../utils/ApolloClient';

const MeQuery = gql`
  ${profileFragment.profile}
  ${personFragment.person}
  query {
    me {
      ...PersonFields
      currentProfile {
        id
        username
        viewerCanEdit
      }
      profiles {
        ...ProfileFields
      }
      teachers {
        ...ProfileFields
      }
    }
  }
`;

if (window.matchMedia('(display-mode: standalone)').matches) {
  console.log('display-mode is standalone');
}
if (window.navigator.standalone === true) {
  console.log('display-mode is standalone');
}

const NullStateButtons = ({ history }) => (
  <div style={{ display: 'grid', gridTemplateRows: 'auto auto', gridTemplateColumns: '1fr 1fr', gridGap: 10, margin: '200px 20px 20px 20px' }}>
    <Button primary to="/new-profile" history={history} style={{ gridColumn: '1 / 3'}}>
      <Trans>Create Teacher Profile</Trans>
    </Button>
    <Button small to="/accept" history={history}>
      <Trans>Enter Invitation Code</Trans>
    </Button>
    <Button small to="/search" history={history}>
      <Trans>Invite Teacher</Trans>
    </Button>
  </div>
)

const Home = ({ history, match, location, ...props }) => {
  useEffect(() => { window.analytics.page('Home') }, []);
  const isStandalone =
    window.matchMedia('(display-mode: standalone)').matches ||
    window.navigator.standalone === true;
  if (!signedIn()) {
    if (isStandalone) {
      return <SignIn {...props} history={history} />;
    } else {
      return <Landing {...props} history={history} />;
    }
  } else {
    const { loading, data } = useQuery(MeQuery);
    if (loading) {
      return (
        <Surface>
          <NavBar fixed />
          <ContentInset>
            <Loading />
          </ContentInset>
        </Surface>
      );
    }
    const noRedirect = location.state && location.state.noRedirect;
    if (!noRedirect) {
      return (
        <Redirect to={`/posts`} />
      );
    }
    return (
      <Surface>
        <NavBar
          right={
            <Button
              nav
              account
              onClick={() => {
                history.push(`/settings`);
              }}
            />
          }
          fixed
        />
        <ContentInset padding="2em 0 2em 0">
          {data && data.me && data.me.profiles.length === 0 && data.me.teachers.length === 0 && <NullStateButtons history={history} />}
          {data && data.me && data.me.profiles.length > 0 && (
            <SectionHeader>
              <Plural
                value={data.me.profiles.length}
                one="My Profile"
                other="My Profiles"
              />
            </SectionHeader>
          )}
          {loading && <Loading />}
          {data && data.me && data.me.profiles.map(profile => (
            <ProfileRow
              key={profile.id}
              {...profile}
              onClick={() => {
                history.push(`/${profile.username}/media`);
              }}
            />
          ))}
          {data && data.me && data.me.teachers.length > 0 && (
            <SectionHeader>
              <Plural
                value={data.me.teachers.length}
                one="My Teacher"
                other="My Teachers"
              />
            </SectionHeader>
          )}
          {data && data.me && data.me.teachers.map(profile => (
            <ProfileRow
              key={profile.id}
              {...profile}
              onClick={() => {
                history.push(`/${profile.username}/posts`);
              }}
            />
          ))}
        </ContentInset>
      </Surface>
    );
  }
};

export default Home;
