import React, { useState } from 'react';
import styled from 'styled-components';
import Linkify from 'react-linkify';
import { resize } from './utils';
import { ReactComponent as YogaMat } from '../icons/YogaMat.svg';
import Truncate from 'react-truncate';
import { ReactComponent as ShareiOS } from '../icons/ShareiOS.svg';
import { ReactComponent as ShareAndroid } from '../icons/ShareAndroid.svg';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  justify-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
  margin: 1em 0 0 0;
`;

const Name = styled.h2`
  margin: 0rem;
  color: rgba(0, 0, 0, 0.6);
  font-size: 25px;
  font-weight: 700;
  text-align: center;
`;

const Bio = styled.div`
  font-size: 18px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
`;

//Pass src in imageUrl prop, if passed as src it will not get resized.
const ProfilePicImage = styled.img.attrs(props => ({
  src: props.resize
    ? resize((props.imageUrl || 'https://align.yoga/generic-profile-pic.png'), props.tiny ? 100 : 200)
    : (props.imageUrl || 'https://align.yoga/generic-profile-pic.png'),
  alt: props.name
}))`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #CCCCCC;;
`;

export function ProfilePic({ small, tiny, resize = true, ...props }) {
  const size = tiny ? '3rem' : small ? '4rem' : '120px';
  return  <ProfilePicImage size={size} resize={resize} tiny={tiny} {...props} />
}

export const ProfileSummary = ({ bio, name, imageUrl, websiteUrl }) => {
  const [ lines, setLines ] = useState(3);
  return (
    <Wrapper>
      <ProfilePic imageUrl={imageUrl} />
      <Name>{name || <span>&nbsp;</span>}</Name>
      {bio && <Linkify>
          <Bio>
            <Truncate 
              lines={lines} 
              ellipsis={<u>...</u>} 
              onClick={() => setLines(ln => (ln === 3 ? 0 : 3))}
            >
              {bio || <span>&nbsp;</span>}
            </Truncate>
          </Bio>
        </Linkify>}
    </Wrapper>
  );
};

export const Heading = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
`;

function fontWeight({
  bold
}) {
  if (bold) return 'bold';
  return 'normal';
}

export const Text = styled.p`
  color: ${props => props.color ? props.color : props.theme.primary};
  font-size: ${props => 
    props.extraLarge ? '24px' : props.large ? '18px' : props.medium ? '16px' : props.small ? '14px' : '16px'};
  font-weight: ${props => fontWeight(props)};
  letter-spacing: -0.02em;
  line-height: ${props => props.lineHeight ? props.lineHeight : '22px'};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : `0` }
  margin-top: ${props => props.marginTop ? props.marginTop : `0` }
  margin-left: ${props => props.marginLeft ? props.marginLeft : `0` }
  text-align: ${props => props.center ? 'center' : 'left'};
  text-decoration-line: ${props => props.underline ? 'underline' : 'none' };
  text-underline-position: ${props => props.underline ? 'under' : 'auto' };
`;

const NullStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  & > svg {
    width: 80px;
    height: 80px;
  }
`;

export const NullState = ({ icon, inset = true, children }) => {
  return (
    <NullStateWrapper inset={inset}>
      {icon || <YogaMat />}
      {children}
    </NullStateWrapper>
  );
};

export const SectionHeader = styled.h3`
  padding: 10px 0 5px 20px;
`;

export function PlatformShareIcon(props) {
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf("android") > -1;
  const isChromeos = ua.indexOf("cros") > -1;
  if (isAndroid || isChromeos) {
    return <ShareAndroid {...props} />;
  }
  return <ShareiOS {...props} />;
}

const PlatformShareButtonWrapper = styled.div`
  background: #333;
  border: 2px solid white;
  width: 56px;
  height: 56px;
  border-radius: 56px;
  stroke: white;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function PlatformShareButton(props) {
  return <PlatformShareButtonWrapper {...props}>
    <PlatformShareIcon />
  </PlatformShareButtonWrapper>
}
