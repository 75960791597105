import React, {
  useState,
  useReducer,
  useRef,
  useEffect,
  useCallback
} from 'react';
import styled from 'styled-components';
import {
  Form as FormikForm,
  Field as FormikField,
  ErrorMessage as FormikErrorMessage
} from 'formik';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { StackCenter, CardGrid } from './Layouts';
import { AnyPicker, SignedUrl, Button, LoadingSmall } from './Controls';
import { PostCard } from './Post';
import { ReactComponent as Edit } from '../icons/Edit.svg';
import { Text } from '../components/Content';

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
`;

// TODO better name
export const Group = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 120px;
  justify-content: end;
  xpadding: 1rem;
  xalign-content: center;
  xgrid-gap: 0.625rem;
`;

export const Label = styled.label`
  margin-bottom: 0.3em;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.primary};
`;

export const Field = styled(FormikField)`
  -webkit-appearance: none;
  font-family: ${({ component }) =>
    component === 'textarea' ? 'sans-serif' : 'inherit'};
  font-size: ${({ huge }) => (huge ? '24px' : '16px')};
  text-align: ${({ huge }) => (huge ? 'center' : 'left')};
  padding: 12px 24px;
  height: 48px;
  margin-top: 0px;
  margin-bottom: 5px;
  background-color: #fff;
  border-radius: 40px;
  line-height: 22px;
  letter-spacing: -0.02em;
  border-top-right-radius: ${({ left }) => (left ? '0' : 'auto')};
  border-bottom-right-radius: ${({ left }) => (left ? '0' : 'auto')};
  border-top-left-radius: ${({ right }) => (right ? '0' : 'auto')};
  border-bottom-left-radius: ${({ right }) => (right ? '0' : 'auto')};
  border: 1px solid #333;
  border-left: ${({ right, theme }) =>
    right ? '0' : '1px solid #333'};
  &::placeholder {
    color: #ccc;
    font-weight: normal;
  }
`


const AccessoryView = styled.div`
`;

const LabeledFieldWrapper = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: minMax(60px, auto) 1fr;
  grid-template-rows: auto auto;
  margin-bottom: 1em;
  align-items: baseline;
  ${AccessoryView} {
    position: absolute;
    top: 28px;
    right: 8px;
  }
`;

export const LabeledField = (
  { name, 
    label, 
    left, 
    right, 
    errors = null, 
    validate = () => {}, 
    loading = false, 
    ...rest }) => (
  <LabeledFieldWrapper>
    <Label htmlFor={name}>  <Text small bold marginLeft={'24px'}> {label} </Text></Label>
    {/* ErrorMessage internally shows error only if touched. */}
    {errors && errors[name] ? (
          <ErrorMessageNative> 
            { errors[name] } 
          </ErrorMessageNative>
         ) : (
          <ErrorMessage
            name={name}
          />
         )
    } 
    <Field
      id={name}
      name={name}
      left={left ? 1 : 0}
      right={right ? 1 : 0}
      validate={validate}
      {...rest}
      style={{ gridColumn: '1 / span 2' }}
    />
    <AccessoryView>
      { loading && <LoadingSmall /> }
    </AccessoryView>
  </LabeledFieldWrapper>
);

function validatePhoneNumber(value) {
  let error;
  if (!isValidPhoneNumber(value)) {
    error = 'Invalid Phone Number';
  }
  return error;
}

const PhoneField = styled(FormikField)`
  -webkit-appearance: none;
  outline: none;
  font-size: 16px;
  border: none;
  &::placeholder {
    color: #CCCCCC;
    font-weight: normal;
  }
`;

const PhoneLabeledField = React.forwardRef(
  ({ name, onChange, ...rest }, ref) => (
    <PhoneField
      innerRef={ref}
      id={name}
      name={name}
      onChange={e => onChange(e.target.value)}
      validate={validatePhoneNumber}
      {...rest}
      style={{ gridColumn: '1 / span 2' }}
    />
  )
);

export const StyledPhoneInput = React.forwardRef(
  ({ value, onBlur, onChange, onFocus, ...props }, ref) => (
    <PhoneLabeledField
      ref={ref}
      name="phoneNumber"
      autoComplete="tel"
      type="tel"
      placeholder="+12342341234"
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      required
    />
  )
);

const PhoneWrapper = styled.div`
  padding: 12px 24px;
  margin-top: 0px;
  height: 48px;
  background-color: #fff;
  border-radius: 40px;
  border: 1px solid #333;
`;

const PhoneBottomMargin = styled.div`
  margin-bottom: 1em;
`;

export const PhoneFieldDisplay = ({
  name = 'phoneNumber',
  label,
  children
}) => (
  <PhoneBottomMargin>
    <Label htmlFor={name}>  <Text small bold marginLeft={'24px'}> {label} </Text> </Label>
    <ErrorMessage
      name={name}
    />
    <PhoneWrapper>{children}</PhoneWrapper>
  </PhoneBottomMargin>
);

export const Fieldset = LabeledField;


const ButtonFieldNewsletter = styled.div`
  position: relative;
  input {
    width: 100%;
    height: 40px;
    border-radius: 100px;
    padding-left: 15px;
    box-sizing: border-box;
    border: 1px solid #000000;
    font-weight: normal;
    &::placeholder {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.02em;
      color: #CCCCCC;
    }
  }
  button {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    height: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em
  }
`;

export const ButtonNewsletter = ({ icon, ...props }) => (
  <ButtonFieldNewsletter>
    <Field {...props} />
    <Button 
      dark 
      small 
      height={'40px'}
      width={'96px'}
      type="submit" 
      label={'Sign Up'} 
      {...props} 
    />
  </ButtonFieldNewsletter>
);

const ButtonFieldContainer = styled.div`
  position: relative;
  input {
    width: 100%;
    border-radius: 50px;
    padding-left: 15px;
  }
  button {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

export const ButtonField = ({ icon, ...props }) => (
  <ButtonFieldContainer>
    <Field {...props} />
    <Button small primary type="submit" {...props} />
  </ButtonFieldContainer>
);

export const FormHeading = styled.div`
  margin-bottom: 36px;
  margin-top: 24px;
`;

export const FormHelp = styled.div`
  margin: 1em 0;
`;


export const FormError = styled.div`
  margin-top: 1.25rem;
  text-align: center;
  line-height: 1rem;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.5);
`;

// TODO
export const ErrorMessage = styled(FormikErrorMessage).attrs(() => ({
  component: 'div'
}))`
  color: ${props => props.theme.error};
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  margin-right: 20px;
  justify-self: end; 
  text-align: right;
`;

//ErrorMessageNative is for when we cannot use ErrorMessage
export const ErrorMessageNative = styled.div`
  color: ${props => props.theme.error};
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  margin-right: 20px;
  justify-self: end;
  text-align: right;
`;

// TODO
export const ErrorText = styled.div`
  color: ${props => props.theme.error};
  font-size: 12px;
`;

//ImageFieldset can optionally take in a image container component with a imageUrl prop for the image.
export const ImageFieldset = React.memo(
  ({ id, name, component, onComplete, ...rest }) => {
    const [errorMsg, setErrorMsg] = useState(null);
    return (
      <Field
        name={name}
        render={({ field, form }) => {
          const { setFieldValue } = form;
          return (
            <SignedUrl username={id} contentType="image/jpeg">
              {({ mediaUrl, ...props }) => (
                <>
                  <ImageInput
                    {...props}
                    {...rest}
                    src={field.value}
                    component={component}
                    onComplete={({ error }) => {
                      setFieldValue(name, mediaUrl);
                      setErrorMsg(error);
                      onComplete && onComplete();
                    }}
                  />
                  {errorMsg && <InputFeedback error={errorMsg} />}
                </>
              )}
            </SignedUrl>
          );
        }}
      />
    );
  }
);

export const InputFeedback = ({ error }) =>
  error ? <ErrorText>{error}</ErrorText> : null;

export const TextInput = ({
  type,
  id,
  label,
  error,
  value,
  onChange,
  className,
  ...props
}) => {
  return (
    <Label htmlFor={id} error={error}>
      {label}
      <Field
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        className={className}
        {...props}
      />
      <InputFeedback error={error} />
    </Label>
  );
};

export const TextField = styled(FormikField)`
  width: 100%;
  padding: 1em;
  margin-bottom: 0.8em;
  font-size: 1.1em;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.surfaceLine};
  color: ${props => props.theme.primary};
  ::placeholder {
    color: ${props => props.theme.tertiary};
  }
  :focus {
    border-color: ${props => props.theme.surfaceLine};
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px ${props => props.theme.surfaceLine};
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
// TODO: Consolidate InputField and EmailField
export const InputField = ({
  errors,
  touched,
  type,
  placeholder,
  name,
  label,
  values,
  required,
  readonly = false,
  ...props
}) => {
  return (
    <>
      <LabelWrapper>
        <Label htmlFor={name}>{label}</Label>
        {touched[name] && errors && <InputFeedback error={errors[name]} />}
      </LabelWrapper>
      <TextField
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        values={values}
        required={required}
        readonly={readonly}
      />
    </>
  );
};

export const PickMedia = ({ error, items, handleSelect, notSelectable }) => {
  return (
    <>
      <StackCenter>{error}</StackCenter>
      <CardGrid>
        <AnyPicker
          items={items}
          notSelectable={notSelectable}
          Component={PostCard}
          handleSelect={handleSelect}
        />
      </CardGrid>
    </>
  );
};

export const CompoundFields = styled.div`
  width: 100%;
  display: grid;
  padding: 0;
  grid-template-columns: 50% 50%;
`;

// Image Handling in Forms
const reducer = (state, action) => {
  if (action.type === 'startUpload') {
    return { localUrl: state.localUrl, uploading: true };
  } else if (action.type === 'uploadOk') {
    return { localUrl: action.value, uploading: false };
  } else if (action.type === 'uploadFailed') {
    return { localUrl: '', uploading: false };
  }
  return state;
};

const initialState = {
  uploading: false,
  localUrl: ''
};

const useUpload = onComplete => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { uploading, localUrl } = state;

  const upload = (event, signedUrl, contentType) => {
    console.log(`Uploading image`);

    dispatch({ type: 'startUpload' });
    const body = event.target.files[0];
    const headers = { 'content-type': contentType };
    fetch(signedUrl, { method: 'PUT', body, headers })
      .then(response => {
        if (response.ok) {
          console.log(`Done uploading (${response.statusText})`);
          const fileUrl = window.URL.createObjectURL(body);
          dispatch({ type: 'uploadOk', value: fileUrl });
          onComplete({ error: null });
        } else {
          console.log(`${response.status}: ${response.statusText}`);
          onComplete({ error: `Failed to upload image.` });
          dispatch({ type: 'uploadFailed' });
        }
      })
      .catch(error => {
        console.log(`Error uploading (${error})`);
        onComplete({ error: `Failed to upload image.` });
        dispatch({ type: 'uploadFailed' });
      });
  };
  return [uploading, localUrl, upload];
};

const ImageComponentDiv = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > svg {
    bottom: 3%;
    right: -9%
    position: absolute;
  }
`;

const Dimmer = styled.div`
  position: absolute;
  background: #000;
  opacity: 0.1;
  :hover {
    opacity: 0.3;
  }
  border-radius: ${({ borderRadius }) => borderRadius || '50%'}
  width: ${({ width }) => `${width}px`}
  height: ${({ height }) => `${height}px`}
`;

const InvisibleFileInput = styled.input`
  display: none;
`;

const ImageInputWrapper = styled.div`
  position: relative;
  width: fit-content;
  align-self: center;
`;

//uploads the image, using the uploadFile hook.
const ImageInput = React.memo(
  ({
    signedUrl,
    contentType,
    src,
    component = null,
    onComplete,
    width,
    height,
    borderRadius
  }) => {
    const imageRef = useRef();
    const divRef = useRef();
    const [uploading, localUrl, upload] = useUpload(onComplete);
    const [size, setSize] = useState(0);

    useEffect(() => {
      setSize(divRef.current.clientHeight);
    }, []);

    const Component = component;
    
    return (
      <ImageInputWrapper>
        <InvisibleFileInput
          type="file"
          ref={imageRef}
          onChange={useCallback(e => upload(e, signedUrl, contentType), [
            signedUrl,
            contentType
          ])}
          capture
        />
        {component ? (
          <ImageComponentDiv
            onClick={useCallback(() => imageRef.current.click())}
            ref={useCallback(element => (divRef.current = element))}
          >
            {uploading ? <LoadingSmall /> : <Edit width={'32'} height={'32'} />}
            <Dimmer
              height={height || size}
              width={width || size}
              borderRadius={borderRadius}
            />
            <Component
              imageUrl={localUrl || src}
              resize={localUrl ? false : true}
            />
          </ImageComponentDiv>
        ) : (
          <img
            onClick={useCallback(() => imageRef.current.click())}
            src={localUrl || src}
            style={{ width: 100, height: 100 }}
            alt=""
          />
        )}
      </ImageInputWrapper>
    );
  }
);

export const TextArea = styled(LabeledField)`
  padding: 16px 24px 16px 24px; 
  width: 100%;    
  border-radius: 24px;     
  background-color: #fff;
  border: 1px solid #333;
  font-size: 16px;
  height: 170px;
  margin-bottom: 1em;
  resize: none;
  &::placeholder {
    color: #ccc;
    font-weight: normal;
  }
`;