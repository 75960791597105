import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import format from 'date-fns/format';
import {
  postFragment,
} from '../utils/fragments';

export const CreatePost = gql`
  ${postFragment.post}
  mutation($name: String) {
    __typename
    createPost(
      name: $name
    ) {
      ...PostFields
    }
  }
`;

export default function PostNew() {
  useEffect(() => { window.analytics.page('PostNew') }, []);
  const name = format(new Date(), 'MMMM Do');
  //const { loading, data, error } = useQuery(PostNewQuery);
  const [createPost, { data, loading }] = useMutation(CreatePost);
  useEffect(() => {
    createPost({ variables: { 
      name,
    }});
  }, []);

  return (
    <div>
      {loading}
      {data}
    </div>
  )
}