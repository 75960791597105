import React from 'react';
import styled from 'styled-components';
import { NavBar } from '../components/NavBar';

// Sizes from Chrome Dev Tools
const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
};

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  @media ${device.mobileL} {
    height: 90vh;
    max-width: 400px;
    max-height: 800px;
    border-radius: 10px;
    margin: 0 auto;
  }
`;

const Page = styled.div`
  width: 100%;
  height: 100%;

  background: ${({ theme }) => theme.surfaceBackground};

  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 150px 1fr 150px;
  grid-template-areas:
    'header header header'
    'content content content'
    'footer footer footer';
  .header,
  header {
    grid-area: header;
    z-index: 3;
  }
  .content,
  section {
    grid-area: header-start / content-start / footer-end / footer-end;
    overflow: scroll;
    width: 100%;
  }
  .footer,
  footer {
    grid-area: footer;
    z-index: 2;
  }
`;

// TODO rename Page?
export function Surface({ poweredBy = false, children, ...props }) {
  return (
    <Backdrop>
      <PageContainer>
        <Page {...props}>{children}</Page>
        {poweredBy && (
          <p>
            <small>
              Powered by <a href="/">Align</a>
            </small>
          </p>
        )}
      </PageContainer>
    </Backdrop>
  );
}

export const Backdrop = styled.div`
  // background: linear-gradient(-180deg, #bcc5ce 0%, #929ead 98%),
  //   radial-gradient(
  //     at top left,
  //     rgba(255, 255, 255, 0.3) 0%,
  //     rgba(0, 0, 0, 0.3) 100%
  //   );
  background: #eee;
  background-blend-mode: screen;
  width: 100%;
  min-height: 100%;
  padding: 0;
  @media ${device.mobileL} {
    padding: 30px;
  }
`;

// TODO default app level paddings should be in theme. NavBarHeight is 60px
//The left/right padding should be updated in FooterButton 
export const ContentInset = styled.section`
  position: relative;
  padding: ${props => props.padding || '60px 24px 80px 24px'};
  max-width: 500px;
  margin: 0 auto;
  height: 100%;
  grid-area: content;
`;

export const Stack = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ gridgap }) => (gridgap ? gridgap : '10px')};
  justify-items: stretch;
  width: 100%;
  max-width: 500px;
  height: ${props => props.height ? props.height : '100%'};
`;

export const StackCenter = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  grid-gap: ${props => (props.gridgap ? props.gridgap : '1rem')};
`;

export const FormStack = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1em 1em 1em;
`;

export const CardGrid = styled.div`
  display: grid;
  grid-gap: 24px 24px;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  justify-items: stretch;
`;

export const HorizontalButtons = styled.div`
  margin: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`;

export function EmptyPage() {
  return (
    <Surface>
      <NavBar fixed transparent />
      <ContentInset>
      </ContentInset>
    </Surface>
  );
}
