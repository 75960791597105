import { css } from 'styled-components';
import { createBrowserHistory } from "history";

export const media = {
  handheld: (...args) => css`
    @media (max-width: 420px) {
      ${css(...args)};
    }
  `
};

//TODO: right size the image
export const resize = (url, resize) => {
  if (!url) return '';

  let size = 500;
  if (resize) {
    size = resize;
  }

  let rebased = url
    .replace('alignlabs.s3-us-west-1.amazonaws.com', 'align.imgix.net')
    .replace('alignlabs.s3.us-west-1.amazonaws.com', 'align.imgix.net');

  return `${rebased}?fit=clamp&w=${size}&h=${size}&auto=format`;
};

export const goBackOrHome = app => {
  let history = createBrowserHistory();
  if (app) {
    history.goBack();
  } else {
    history.push(`/`);
  }
};
