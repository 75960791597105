//import React from 'react';
import styled from 'styled-components';

export const PrimaryButton = styled.button`
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  background: ${props => props.disabled ? props.theme.primaryButtonBgDisabled : props.theme.primaryButtonBg};
  &:active {
    background: rgba(67, 205, 163, 0.8);
  }
  color: white;
  height: 56px;
  width: 100%;
  border-radius: 56px;
  letter-spacing: -0.02em;
  fill: white;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 10px;
  }
`;
