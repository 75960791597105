import styled from 'styled-components';
import { device } from '../components/Layouts';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 64px;
    max-width: 832px;
    justify-self: center;
  };
`;

export const LeftWrapper = styled.div`
  display: grid;
  grid-template-columns: 100px 100px;
  grid-column-gap: auto;
  margin: 60px 0px 60px 0px;
  justify-self: center;
  @media ${device.tablet} {
    margin: 60px 0px 60px 64px;
    grid-column-gap: 32px;
    width: 350px;
    justify-self: start;
  }
`;

export const RightWrapper = styled.div`
  text-align: center;
  margin: 0px 0px 60px 0px;
  @media ${device.tablet} {
    text-align: right;
    margin: 60px 0px 60px 0px;
    justify-self: right;
  }
`;

export const Text = styled.div`
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 8px;
  font-weight: normal;
`;

export const TextHover = styled(Text)`
  :hover {
    text-decoration-line: underline;
    text-underline-position: under;
  }
`

export const BoldText = styled(Text)`
  font-weight: bold;
`;

export const ThinText = styled(Text)`
  display: ${props => props.inline ? 'inline-block' : 'block'};
  color: rgba(255, 255, 255, 0.5);
`;

export const ThinTextHover = styled(ThinText)`
  :hover {
    text-decoration-line: underline;
    text-underline-position: under;
  }
`

export const LeftJustifyText = styled.div`
  justify-self: left;
`;

export const RightJustifyText = styled.div`
  justify-self: right;
`;

export const NavMarketing = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: black;
  height: 44px;
`;

export const SignIn = styled.div`
  width: 45px;
  height: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.04em;
  color: #ffffff;
  text-align: right;
  margin-right: 40px;
`;

export const Section = styled.div`
  display: grid;
  grid-template-columns: auto;
  padding: 60px 0px 0px 0px;
  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 64px;
    max-width: 832px;
    justify-self: center;
    padding: 64px 0px;
  };
  @media ${device.laptop} {
    padding: 120px 0px;
  };
`;

export const SectionY = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin: 82px 32px 18px 32px;
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 64px;
    max-width: 832px;
    justify-self: center;
    margin: 64px 0px;
    align-items: center;
  };
`;

export const HeroTitle = styled.div`
  text-align: center;
  @media ${device.tablet} {
    text-align: left;
  };
`;

export const HeroDescription = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #333333;
  @media ${device.tablet} {
    font-family: Inter;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    text-align: left;
  };
  @media ${device.laptop} {
    width: 340px;
  };
`;

export const InfoBox = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 16px;
  text-align: center;
  justify-items: center;
  margin: 0px 32px;
  margin-bottom: 64px;
  @media ${device.tablet} {
    grid-template-columns: 64px auto;
    grid-gap: 16px;
    justify-self: start;
    text-align: left;
    justify-content: left;
    margin: 0px;
  };
`;

export const HeroItems = styled.div`
  display: grid;
  justify-content: center;
  grid-gap: 8px;
  @media ${device.tablet} {
    display: grid;
    grid-gap: 6px;
  };
`;

export const InfoText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #333333;
`;

export const InfoHeading = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: #333333;
  margin-bottom: 4px;
`;

export const ButtonCenter = styled.div`
  justify-self: center; 
  margin-top: 16px;
  @media ${device.tablet} {
    justify-self: left; 
    margin-top: 18px;
  };
`;

export const StyledLine = styled.hr`
  border: 1px solid #e1e1e1;
  margin-block-end: 0px;
  margin-block-start: 0px;
  display: ${({hide}) => hide ? 'none' : 'block'};
  @media ${device.tablet} {
    display: block;
  };
`;

export const ImageDiv = styled.div`
  background-image: url('/hero.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  justify-self: center; 
  height: 270px;
  width: 100%;
  border-top: 2px solid #E5E5E5;
  border-bottom: 2px solid #E5E5E5;
  @media ${device.tablet} {
    display: ${props => props.height ? 'auto' : 'none'};
    position: absolute;
    right: 0px;
    top: 114px;
    width: calc(50% - 32px - 64px);  
    height: ${props => `${props.height}px`};
    border-left: 2px solid #E5E5E5;
    border-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  };

  @media ${device.laptop} {
    display: ${props => props.height ? 'auto' : 'none'};
    top: 170px;
    height: ${props => `${props.height}px`};
  };
`;

export const ContentInset = styled.section`
  display: grid;
  align-items: center;
  margin: 0 auto;
  grid-area: content;
  background: ${props => props.background};
  height: 100%;
  @media ${device.tablet} {
    padding: 0px 64px;
  };
  @media ${device.laptop} {
    padding: 0px 120px;
    height: ${props => props.height ? props.height: '100%'};
  };
  @media ${device.laptopL} {
    padding: 0px 150px;
    height: ${props => props.height ? props.height: '100%'};
  };
`;

export const ButtonText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  padding: 0px 20px;
`;

export const CenterSoundWave = styled.div`
  display: grid;
  justify-content: center;
  margin-bottom: 32px;
  max-width: ${props => `calc(${props.width}px - 32px)`};  
  overflow: hidden;
  @media ${device.tablet} {
    margin-bottom: 0px;
  }
`;