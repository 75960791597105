import React, { useEffect } from 'react';
import { Mutation } from '@apollo/react-components';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { I18n } from '@lingui/react';
import { t, Trans } from '@lingui/macro';
import { ToastConsumer } from 'react-toast-notifications';
import { Link } from 'react-router-dom';
import { LabeledField, ImageFieldset, Form, TextArea } from '../components/Forms'; 
import { NavBar } from '../components/NavBar';
import { VersionRow } from '../components/Rows';
import { Surface, ContentInset, HorizontalButtons, Stack } from '../components/Layouts';
import { Button } from '../components/Controls';
import { PrimaryButton } from '../components/PrimaryButton';
import { ProfilePic, Text } from '../components/Content';
import { personFragment } from '../utils/fragments';
import { signOut } from '../utils/ApolloClient';
import 'react-phone-number-input/style.css'
import preval from 'preval.macro'

const SettingsQuery = gql`
  ${personFragment.person}
  query {
    me {
      ...PersonFields
      phoneNumber
      bio
      teachers {
        id
      }
      profiles {
        id
        username
      }
      currentProfile {
        id
        username
        name
        viewerCanEdit
      }
    }
  }
`;

const UpdatePerson = gql`
  ${personFragment.person}
  mutation(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $bio: String
    $location: String
    $imageUrl: String
    $phoneNumber: String
    $instagramUsername: String
  ) {
    updatePerson(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      bio: $bio
      location: $location
      imageUrl: $imageUrl
      phoneNumber: $phoneNumber
      instagramUsername: $instagramUsername
    ) {
      ...PersonFields
      phoneNumber
      bio
    }
  }
`;

// Here is a more thorough phone number validation, but the package
// size could be an issue. https://github.com/ruimarinho/google-libphonenumber/issues/89
const PersonForm = ({ loading, values, onSubmit, history }) => (
  <Formik
    initialValues={loading ? {
      firstName: '',
      lastName: '',
      email: '',
      instagramUsername: '',
      phoneNumber: '',
      bio: '',
      imageUrl: '',
    } : values}
    enableReinitialize={true}
    onSubmit={onSubmit}
    validationSchema={Yup.object().shape({
      firstName: Yup.string().required(<Trans>Required</Trans>),
      lastName: Yup.string().required(<Trans>Required</Trans>),
      email: Yup.string()
        .email('Invalid email address')
        .required(<Trans>Required</Trans>),
      instagramUsername: Yup.string().matches(/^[A-Za-z0-9_.-]+$/, <Trans>A-Z, 0-9, _, -, or .</Trans>).nullable(),
    })}
    render={({ values, valid, isSubmitting, setFieldValue }) => (
      <I18n>
        {({ i18n }) => (
          <Form>
            <div style={{height:20}} />
            <ImageFieldset
              name="imageUrl"
              id="imageUrl"
              component={ProfilePic}
            />
            <div style={{ height: '20px' }} />
            <LabeledField
              name="firstName"
              label={i18n._(t`First name`)}
              placeholder={i18n._(t`First`)}
              required
            />
            <LabeledField
              name="lastName"
              label={i18n._(t`Last name`)}
              placeholder={i18n._(t`Last`)}
              required
            />
            <LabeledField
              name="email"
              type="email"
              label=<Trans>Email</Trans>
              placeholder="foo@example.com"
            />
            <TextArea
              type="text"
              name="bio"
              label=<Trans>Bio</Trans>
              placeholder={i18n._(t`For me, yoga is about the balance between strength and flexibility, exertion, and releasing. So, my classes combine both strength and mobility work in a dynamic, Vinyasa Flow style. Expect to move, sweat, and breathe.`)} 
              component={"textarea"}
            />
            <LabeledField
              name="instagramUsername"
              type="text"
              label=<Trans>Instagram username</Trans>
              placeholder="juniper"
              prefix="@"
            />
            <Stack gridgap="16px">
              <PrimaryButton disabled={loading || isSubmitting} type="submit">
                <Trans>Save</Trans>
              </PrimaryButton>
              <Button secondary onClick={() => {signOut(); history.push('/')}}>
                <Trans>Sign out</Trans>
              </Button>
            </Stack>
          </Form>
        )}
      </I18n>
    )}
  />
);

export default function Settings({ history }) {
  useEffect(() => { window.analytics.page('Settings') }, []);
  const { loading, data, error } = useQuery(SettingsQuery);
  return (
    <Surface>
      <NavBar
        left={<Link to="/posts"><Button nav close /></Link>}
        fixed
      >
        <Text large bold>
          <Trans>Profile</Trans>
        </Text>
      </NavBar>
      <ContentInset>
        <Mutation mutation={UpdatePerson}>
          {mutate => (
            <ToastConsumer>
              {({ add }) => (
                <div>
                  {!error && (
                    <PersonForm
                      loading={loading}
                      values={data.me}
                      history={history}
                      onSubmit={(variables, { setSubmitting }) => {
                        setSubmitting(true);
                        console.log('submitting');
                        mutate({ variables })
                          .then(result => {
                            setSubmitting(false);
                            history.push('/posts');
                            add(<Trans>Saved</Trans>, {
                              appearance: 'success',
                              autoDismiss: true
                            });
                          })
                          .catch(res => {
                          setSubmitting(false);
                          res.graphQLErrors
                            ? res.graphQLErrors.map(err =>
                                console.log(err.message)
                              )
                            : console.log(`Error : ${res}`);
                          });
                      }}
                    />
                  )}
                </div>
              )}
            </ToastConsumer>
          )}
        </Mutation>
        <HorizontalButtons>
          <Link to="/terms" style={{justifySelf: 'flex-end'}}> 
            <Text small underline lineHeight="17px"> Terms of Service </Text> 
          </Link> 
          <Link to="/privacy" style={{justifySelf: 'flex-start'}}> 
            <Text small underline lineHeight="17px"> Privacy Policy  </Text> 
          </Link>
        </HorizontalButtons>
        <Link to="/dev">
          <VersionRow>
            <Trans>
              Version {preval`module.exports = new Date().toISOString().slice(0,10).replace(/-/g,"");`}
            </Trans>
          </VersionRow>
        </Link>
      </ContentInset>
    </Surface>
  )
}
