/* global analytics */
import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { useToasts, DefaultToastContainer } from 'react-toast-notifications';
import { ToastConsumer } from 'react-toast-notifications';
import { Trans } from '@lingui/macro';
import { localisedError } from '../utils/errors';
import { NetworkStatus } from '../utils/ApolloClient';
import { Text } from './Content';

const toastStates = {
  entering: `opacity: 0;`,
  entered: `opacity: 1;`,
  exiting: `opacity: 0.5;`,
  exited: `opacity: 0;`,
}
  
export const Toast = styled.div`
  ${({ transitionState }) => toastStates[transitionState] }
  background: rgba(51, 51, 51, 0.5);
  margin-bottom: 1em;
  border-radius: 56px;
  color: white;
  height: 44px;
  width: 250px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  transition: opacity 0.2s linear;
`;

export const CustomToastContainer = props => (
  <DefaultToastContainer {...props} style={{ zIndex: 9999 }} />
);

const Status = React.forwardRef((props, ref) => {
  const [refresh, setRefresh] = useState(false);
  
  useImperativeHandle(ref, () => ({
    refresh: () => {
      setRefresh(!refresh);
    }
  }));

 return (<Text center> Uploading... {props.getProgress()}% </Text>)
})


export const UploadingStatus = ({ media, progress, complete }) => {
  const renderRef = useRef('render');
  const progressRef = useRef('progress');
  progressRef.current = progress;
  const getProgress = () => {  
    return progressRef.current 
  };
  const toastId = useRef();
  const { addToast, removeToast } = useToasts();

  useEffect(() => {
    if (progress > 0 && progress < 100 && !toastId.current) {
      addToast(<Status ref={renderRef} getProgress={getProgress}/>, { appearance: 'success' }, id => toastId.current = id );
    }
  
    if (complete) {
      progressRef.current = null;
      if(toastId.current) {
        removeToast(toastId.current, () => toastId.current = null);
        //addToast("Done", { appearance: 'success', autoDismiss: true });
      }
    }

    if (progress > 0 && progress < 100) {
      if (renderRef.current && renderRef.current.refresh) {
        renderRef.current.refresh();
      }
    }
  },[complete, progress, media.id]);  
  return null;
};

const Toaster = ({ error, add }) => {
  useEffect(
    () => {
      console.log('the error is ', error);
      if (error.networkError) {
        analytics.track('Network Error', {
          usermsg: error.networkError.message
            ? error.networkError.message
            : '???',
          stack: error.networkError.stack ? error.networkError.stack : '???'
        });
        if (error.networkError.message === 'Failed to fetch') {
          add(<Trans>Check your network connection</Trans>, {
            appearance: 'error',
            autoDismiss: true
          });
        } else {
          error.networkError.message &&
            add(error.networkError.message, {
              appearance: 'error',
              autoDismiss: true
            });
        }
      } else {
        if (error.graphQLErrors) {
          // todo each, not map
          error.graphQLErrors.map(err => {
            analytics.track('API Error', {
              usermsg: err.message ? err.message : '???',
              stack: err.path ? JSON.stringify(err.path) : '???'
            });
            add(localisedError(err), { appearance: 'error', autoDismiss: true });
            return err.message;
          });
        } else {
          // Should never come here?
          analytics.track('Unknown Error', {
            usermsg: '???',
            stack: JSON.stringify(error)
          });
        }
      }
    },
    [error]
  );
  return null;
};

export const ToastAPIErrors = () => {
  return (
    <ToastConsumer>
      {({ add }) => (
        <NetworkStatus
          render={props => {
            const { error } = props;
            return error ? <Toaster error={error} add={add} /> : null;
          }}
        />
      )}
    </ToastConsumer>
  );
};
