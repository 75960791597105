import React from 'react';
import { Trans } from '@lingui/macro';

const errorMsgs = {
  'ALREADY_REGISTERED' : <Trans> Already registered, please sign in </Trans>
} 

export const localisedError = (error) => {
  //Get the localised msg, using the error code
  let msg = errorMsgs[error.extensions && error.extensions.code];
  //If no localised error, return error message sent by server
  if (typeof msg === 'undefined') {
    return error.message;
  }
  return msg;  
}
